import React from 'react'

import {
  IconCancelled,
  IconDisk,
  IconError,
  IconInfo,
  IconInProgress,
  IconQuestionAlt,
  IconReassigned,
  IconStop,
  IconSuccess,
  IconWaiting,
  ILibIcon,
  ILibLabelProps,
  ILibOption
} from '@infologistics/frontend-libraries'

import { Common, ConstraintsTypeLabel, Document, Roles, Stage } from './translations'

import { IActionButtonTheme } from '@app/types'
import { IParams } from '@store/types/commonTypes'
import { StageType as StageTypeKeys } from '@store/modules/routes/types'
import { IAllowedAction } from '@store/modules/documents/types'
import { ConstraintType } from '@store/modules/constraints/types'
import { IPreProcessingAiExecutionMode, IPreProcessingJobStatus, IPreProcessingAiModelType, IPreProcessingOcrType, IPreProcessingOcrVolume, IPreProcessingProperties } from '@app/store/modules/metadata/types'
import { IconList } from '@infologistics/frontend-libraries/dist/components/Icon/types'
export const isDevelopment = process.env.REACT_APP_API_ENV === 'development'

export const BASE_URL =
  isDevelopment
    ? 'https://im-api-df-backend-dev.dev.info-logistics.eu/'
    : `https://api.${window.location.hostname}/`

export const SSO_URL =
  isDevelopment
    ? 'https://df-sso-df-backend-dev.dev.info-logistics.eu/'
    : `https://sso.${window.location.hostname}/`

export const PER_PAGE_ITEMS_DEFAULT = 25
export const PER_PAGE_ITEMS_MAX = 200
export const PAGE_DEFAULT = 1
export const MINIMUM_ITEMS_COUNT_TO_SHOW_PAGINATION = 10
export const BADGES_UPDATE_INTERVAL = 60000
export const MAX_TOKEN_CASH_AGE = 10000;
export const MAX_BADGES_COUNT = 99
export const ASIDE_WIDTH_INIT = '12.5rem'
export const FILTERS_DATA_UPDATE_INTERVAL = 600000
export const CNPJ_LENGTH = 14
export const ONE_SYMBOL_WIDTH = 7.8
export const HANDBOOK_MAX_ITEMS = 50
/* logic and value of a single character width as in the Excel
(determined by measuring the width of a column 1 unit wide
without margins with font parameters identical to ours)
*/

export const unitDetails = [
  {
    id: 'piece',
    text: 'шт.'
  },
  {
    id: 'address',
    text: 'адрес'
  },
  {
    id: 'm2',
    text: 'м2'
  },
  {
    id: 'm3',
    text: 'м3'
  },
  {
    id: 'm',
    text: 'мин'
  },
  {
    id: 'h',
    text: 'ч'
  },
  {
    id: 'cm',
    text: 'см'
  },
  {
    id: 'cm2',
    text: 'см2'
  },
  {
    id: 'kg',
    text: 'кг'
  },
  {
    id: 'month',
    text: 'мес'
  }
]

export const SuccessCode = {
  GET: 200,
  POST: [200, 201, 204],
  PUT: [200, 201, 204],
  DELETE: [200, 202, 204]
}

export const temporaryPostResponseCode = {
  POST: 204,
  POST_200: 200
}

export const ErrorCode = {
  CONFLICT: 409,
  NOT_AUTH: 401,
  IP_RESTRICTION: 403,
  NOT_FOUND: 404
}

export const InputLength = {
  MIN: 1,
  MAX: 50
}

export const InputStringLength = {
  MIN: 3,
  MAX: 2000
}

export const LoginLength = {
  MIN: 3,
  MAX: 15
}

export const NameLength = {
  MIN: 3,
  MAX: 30
}

export const SurnameLength = {
  MIN: 3,
  MAX: 30
}

export const PatronymicLength = {
  MIN: 3,
  MAX: 30
}

export const PasswordLength = {
  MIN: 6,
  MAX: 20
}

export const PositionLength = {
  MIN: 1,
  MAX: 2000
}

export const CodeLength = {
  MIN: 3,
  MAX: 15
}

export const PhoneLength = {
  MIN: 10,
  MAX: 18
}

export const OrganizationDetailsLength = {
  ITN: 10,
  CIO: 9
}

export const InnLength = {
  MIN: 10,
  MAX: 12
}

export const FieldKeyLength = {
  MAX: 20
}

export const FieldOrderValue = {
  MAX: 9999,
  MIN: 0
}

export const FieldHintLength = {
  MAX: 250
}

export const FieldRegexpLength = {
  MAX: 250
}

export const DocumentCommentLength = {
  MAX: 500
}

export const DocumentTypeKeyLength = {
  MAX: 40
}

export const DocumentTypeCommentLength = {
  MAX: 250
}

export const DocumentTypeTitleLength = {
  MAX: 250
}

export const DocumentTypeHintLength = {
  MAX: 250
}

export const SearchStrLength = {
  MIN: 3
}

export const FieldLabelLength = {
  MAX: 30
}

export const FieldPlaceholderLength = {
  MAX: 250
}

export const DivisionNameLength = {
  MIN: 4,
  MAX: 2000
}

export const ContractorFieldMaxLength = {
  ADDR_TEXT: 1000,
  COMMENT: 500,
  EXTERNAL_KEY: 30,
  EXTERNAL_KEY_LOCAL: 255,
  FOREIGN_ADDRESS: 1000,
  FOREIGN_COUNTRY: 50,
  NAME_SHORT: 2000,
  NAME: 2000,
  OGRN: 30
}

export const ContractorFieldMinLength = {
  ADDR_TEXT: 4,
  FOREIGN_ADDRESS: 4,
  FOREIGN_COUNTRY: 2,
  NAME_SHORT: 4,
  NAME: 4,
  OGRN: 6
}

export const DESCRIPTION_MAX_LENGTH = 500

export const KPP_LENGTH = 9

export const BIK_LENGTH = 9

export const ACCOUNT_LENGTH = 20

export const ROUTE_NAME_MAX_LENGTH = 250

export const CropSettings = {
  width: 165,
  height: 35,
  border: 25,
  color: [0, 0, 0, 0.3],
  rotate: 0
}

export const UserRoles: { [key in UserRole]: { label: ILibLabelProps['status']; title: string } } = {
  ADMINISTRATOR: {
    label: 'warning',
    title: Roles.ADMINISTRATOR
  },
  ORGANIZATION: {
    label: 'primary',
    title: Roles.ORGANIZATION
  },
  USER: {
    label: 'success',
    title: Roles.USER
  }
}

export const UserStatuses: { [key in UserStatus]: { label: ILibLabelProps['status']; title: string } } = {
  ACTIVE: {
    label: 'success',
    title: Common.active
  },
  BLOCKED: {
    label: 'danger',
    title: Common.blocked
  }
}

export const CurrencyReg = new RegExp('^[0-9]+([.,])?([0-9]{1,2})?$')

export const Currencies = {
  ruble: '₽',
  dollar: '$',
  euro: '€'
}

export const API_DIADOC = 'https://diadoc-api.kontur.ru'

export const ResponseCode = {
  DEL: 204,
  GET: 200,
  NO_CONTENT: 204,
  POST: 201,
  PUT: 201,
  ERROR: 400,
  NO_AUTH: 403,
  NOT_FOUND: 404
}

export const TestData = {
  oguid: '1afa757e-3024-4519-837e-e06e24d8e32a',
  errorResponse: {
    data: undefined,
    status: ResponseCode.ERROR,
    response: {
      messageText: 'HTTP 400 Bad Request'
    }
  }
}

export const TIME_OF_SHOW = 3000 // duration of the notification display

export const ANIMATION_CLASS = {
  animationIn: 'fadeIn',
  animationOut: 'fadeOut slow'
}

export const DIRECTION_COLORS = {
  INCOMING: 'success-500',
  OUTGOING: 'warning-500'
}

export enum DirectionValue {
  INCOMING = 'INCOMING',
  INTERNAL = 'INTERNAL',
  INTERNAL_WITH_SIGNATURE = 'INTERNAL_WITH_SIGNATURE',
  OUTGOING = 'OUTGOING'
}

export const MILLISECONDS_IN_SECOND = 1000
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_SECOND*60*60*24;

export enum FlowState {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  WAITING = 'WAITING'
}

export enum StatusIcon {
  ERROR = 'ERROR',
  PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  UNKNOWN = 'UNKNOWN',
  INFO = 'INFO',
  FINISHED = 'FINISHED'
}

export enum Severity {
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING'
}

export const SEVERITY_COLORS = {
  [Severity.ERROR]: 'danger-500',
  [Severity.INFO]: 'info-500',
  [Severity.SUCCESS]: 'success-500',
  [Severity.UNKNOWN]: 'gray-500',
  [Severity.WARNING]: 'warning-500'
}

export const SEVERITY_PRIORITY = [
  Severity.ERROR,
  Severity.WARNING,
  Severity.SUCCESS,
  Severity.INFO,
  Severity.UNKNOWN,
  Severity.FINISHED
]

export enum RegisterField {
  ALIAS = 'alias',
  ARCHIHE = 'archive',
  CONTACTS = 'contacts',
  CONTRACTOR = 'contractor',
  EMAIL = 'email',
  FLOW_EXCHANGE = 'flowExchange',
  FLOW = 'flow',
  EXCHANGE = 'exchange',
  IS_FOREIGN = 'isForeign',
  NAME = 'name',
  NAME_SHORT = 'nameShort',
  OGRN = 'ogrn',
  ORG_TYPE = 'orgType',
  SURNAME = 'surname',
  PASSWORD = 'password',
  PATRONYMIC = 'patronymic'
}

export enum RegisterComponent {
  COMPLETED = 'completed',
  CONTACT_INFO = 'contactInfo',
  ORGANIZATION_INFO_FOREIGN = 'organizationInfoForeign',
  ORGANIZATION_INFO_SIMPLE = 'organizationInfoSimple',
  ORGANIZATION_INFO_SUGGEST = 'organizationInfoSuggest',
  ORGANIZATION_TYPE = 'organizationType'
}

export const registerStepsTotal = 3

export enum InputName {
  ADDITIONAL_RECIPIENTS = 'additionalRecipients',
  DIRECTION = 'direction',
  RECIPIENT = 'recipient',
  RECIPIENT_ACTION_TYPE = 'recipientActionType',
  RECIPIENT_SIGN_TYPE = 'recipientSignType',
  IS_PROXY_PRESENT = 'isProxyPresent',
  IS_REQUIRE_WORKFLOW = 'isRequireWorkflow',
  IS_USER_RECIPIENT = 'isUserRecipient',
  PACKAGE_KEY = 'packageKey',
  PROXY_ORG = 'proxyOrg',
  RECIPIENT_ORG = 'toOrg',
  SIGNER = 'signer',
  IS_ADDITIONALLY_NOTIFICATION = 'isAdditionallyNotification',
  IS_ADDITIONALLY_FIELDS_NOTIFICATION = 'isAdditionallyFieldsNotification',
  DEPENDENT_FIELDS = 'dependentFields'
}

export enum FieldName {
  ADDR_TEXT = 'addrText',
  COMMENT = 'comment',
  CONTRACTOR = 'contractor',
  CURRENCY = 'currency',
  DETAILS = 'details',
  DIRECTION = 'direction',
  DIVISION_KPP = 'divisionKpp',
  DIVISION_NAME = 'divisionName',
  DIVISION_MANAGER = 'divisionManager',
  DIVISION_OGUID = 'divisionOguid',
  DIVISION_PARENT = 'divisionParent',
  DOCUMENT_DATE = 'documentDate',
  DOCUMENT_NUMBER = 'documentNumber',
  EXTERNAL_KEY = 'externalKey',
  FILENAME = 'filename',
  FOREIGN_ADDRESS = 'foreignAddress',
  FOREIGN_COUNTRY = 'foreignCountry',
  GROUNDS = 'grounds',
  HAS_VAT = 'hasVat',
  INITIATOR = 'initiatorOguid',
  INN = 'inn',
  IS_FLOW_REQUIRED = 'isFlowRequired',
  IS_REQUIRE_RECIPIENT_SIGNATURE = 'isRequireRecipientSignature',
  IS_VALIDATED = 'isValidated',
  KPP = 'kpp',
  NAME = 'name',
  NAME_SHORT = 'nameShort',
  OGRN = 'ogrn',
  START_FLOW = 'startFlow',
  SUB_ORG = 'subOrgOguid',
  SUM_TOTAL = 'sumTotal',
  SUM_VAT = 'sumVat',
  TYPE = 'type',
  VAT = 'sumVat',
  WITHOUT_VAT = 'withoutVat',
  SUM_WITHOUT_VAT = 'sumWithoutVat',
  PRE_PROCESSING_JOB_OGUID = "preProcessingJobOguid",
}

export enum FieldType {
  BOOLEAN = 'BOOLEAN',
  CONTRACTOR = 'CONTRACTOR',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  DICTIONARY = 'DICTIONARY',
  DIRECTION = 'DIRECTION',
  FILE = 'FILE',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export enum LocaleType {
  EN_US = 'en_US',
  EN_GB = 'en_GB',
  PT = 'pt_PT',
  RU = 'ru_RU'
}

export const cutSize = -15

export const SEND_STATUSES = {
  error: 'ERROR',
  error_in_proxy: 'ERROR_IN_PROXY',
  success: 'SUCCESS',
  waiting_proxy_signature: 'WAITING_PROXY_SIGNATURE',
  waiting_to_send: 'WAITING_TO_SEND',
  waiting_to_send_proxy: 'WAITING_TO_SEND_PROXY',
  waiting_sender_signature: 'WAITING_SENDER_SIGNATURE'
}

export enum ShowMode {
  NEED_TO_ACTION = 'NEED_TO_ACTION',
  TODOS_ONLY = 'TODOS_ONLY'
}

export enum TaskDirection {
  TO_ME = 'TO_ME',
  FROM_ME = 'FROM_ME'
}

export enum DateFormat {
  DATE = 'dd.MM.yy',
  DATE_FULL_YEAR = 'dd.MM.yyyy',
  DATE_FULL_YEAR_DATEPICKER = 'dd.MM.yyyy',
  DATETIME = 'dd.MM.yy HH:mm',
  DATETIME_FULL_YEAR = 'dd.MM.yyyy HH:mm',
  DATETIME_FULL_YEAR_DATEPICKER = 'dd.MM.yyyy, HH:mm',
  FULL_YEAR_DATE = 'YYYY-MM-DD',
  TIME_ONLY = 'HH:mm'
}

export enum TextColorClass {
  BRAND = 'text-brand',
  DANGER = 'text-danger',
  INFO = 'text-info',
  MUTED = 'text-muted',
  PRIMARY = 'text-primary',
  SUCCESS = 'text-success',
  WARNING = 'text-warning'
}

export enum Space {
  EM = '\u2003',
  EN = '\u2002',
  NON_BREAK = '\u00a0',
  STANDART = '\u0020',
  THIN = '\u2009'
}

export enum AccessMode {
  ALLOWED = 'ALLOWED',
  BLOCKED = 'BLOCKED',
  INITIATOR = 'INITIATOR'
}

export enum SignatureParameters {
  NONE = 'none',
  SENDER = 'sender',
  SENDER_AND_RECIPIENT = 'sender_and_recipient'
}

export enum ApplyToExternal {
  APPLY_ONLY_TO_EXTERNAL = 'applyOnlyToExternal',
  APPLY_TO_EXTERNAL = 'applyToExternal',
  NOT_APPLY_TO_EXTERNAL = 'notApplyToExternal'
}

export const Languages = {
  EN: 'en',
  PT: 'pt',
  RU: 'ru'
}

export enum LanguagesNames {
  EN = 'English',
  PT = 'Português',
  RU = 'Русский'
}

export enum LanguageNamesShort {
  ru = 'Ru',
  en = 'En',
  pt = 'Pt'
}

export enum Instance {
  GLOBAL = 'global',
  BRASIL = 'com.br',
  RUSSIAN = 'ru',
  SOUTH_AFRICA = 'co.za'
}

export enum PhoneVerifyCountry {
  US = 'us',
  RU = 'ru',
  BR = 'br',
  ZA = 'za'
}

export const NS = [
  'administrator',
  'auth',
  'common',
  'contractor',
  'crypto',
  'currency',
  'document',
  'documents',
  'error',
  'export_modal',
  'filters',
  'group',
  'handbooks',
  'language',
  'massiveActions',
  'menu',
  'messages',
  'notification',
  'organizationList',
  'pagination',
  'preProcessing',
  'profile',
  'recipient',
  'route',
  'tasks',
  'user',
  'utd'
]

export const DEFAULT_LANGUAGE = Languages.EN

export const TASK_TYPES = {
  approval: 'APPROVAL',
  acquaintance: 'ACQUAINTANCE',
  processing: 'PROCESSING',
  signing: 'SIGNING',
  signing_simple: 'SIGNING_SIMPLE',
  todo: 'TODO',
  not_started: 'NOT_STARTED'
}

export enum HistoryDirection {
  IN = 'income',
  OUT = 'outcome'
}

export enum DefaultSubMenuItem {
  ALL = 'all',
  RECEIPTS = 'receipts',
  INCOMING = 'incoming',
  IN_PROGRESS = 'in-progress',
  OUTGOING = 'outgoing'
}

export enum TasksSubMenuItem {
  DOCUMENTS = 'documents',
  MESSAGES = 'messages'
}

export enum UserMenuItem {
  PROFILE = 'profile',
  LOGOUT = 'logout'
}

export const PathServiceName = {
  PARENT: '/:parent',
  ID: '/:id',
  TYPE: '/:type',
  ASTERISK_SYMBOL: '/*',
  ADD: '/add',
  EDIT: '/edit'
}

export const RouteName = {
  ACCESS_GROUPS: '/access-groups',
  ADD_ORG: '/add-org',
  CONSTRAINTS: '/constraints',
  CONTRACTORS: '/contractors',
  DEFAULT: '/',
  DICTIONARIES: '/dictionaries',
  DIVISIONS: '/divisions',
  MATCHING: '/matching',
  DOCUMENT_TYPES: '/document-types',
  DOCUMENTS: '/documents',
  FIELDS: '/fields',
  FORGOT: '/forgot-password',
  INVITES: '/invites',
  GROUPS: '/groups',
  SETTINGS: '/settings',
  MESSAGES: '/messages',
  METADATA: '/metadata',
  ADMINISTRATION: '/administration',
  ORGS: '/orgs',
  ORGANIZATION_URL: '/:org',
  PROFILE: '/profile',
  ROUTES: '/routes',
  SIGN_IN: '/login',
  SIGN_UP: '/register',
  SSO_AUTH: '/by-sso',
  TASKS: '/tasks/',
  USERS: '/users',
  RECEIPTS: '/receipts',
  UTD: '/utd',
  REQUISITES: '/org',
  FILTERS: '/filters',
  APPLICATIONS: '/applications'
}

export const Section = {
  ACCESS_GROUPS: `${RouteName.SETTINGS}${RouteName.ACCESS_GROUPS}`,
  CONSTRAINTS: `${RouteName.SETTINGS}${RouteName.CONSTRAINTS}`,
  CONSTRAINTS_ADD: `${RouteName.SETTINGS}${RouteName.CONSTRAINTS}${PathServiceName.ADD}`,
  CONTRACTORS_ADD: `${RouteName.CONTRACTORS}${PathServiceName.ADD}`,
  DIVISIONS: `${RouteName.SETTINGS}${RouteName.DIVISIONS}`,
  DIVISIONS_ADD: `${RouteName.SETTINGS}${RouteName.DIVISIONS}${PathServiceName.ADD}`,
  DOCUMENT_TYPES: `${RouteName.SETTINGS}${RouteName.DOCUMENT_TYPES}`,
  FIELDS: `${RouteName.SETTINGS}${RouteName.FIELDS}`,
  GROUPS: `${RouteName.ADMINISTRATION}${RouteName.GROUPS}`,
  GROUPS_ADD: `${RouteName.ADMINISTRATION}${RouteName.GROUPS}${PathServiceName.ADD}`,
  DICTIONARIES: `${RouteName.SETTINGS}${RouteName.DICTIONARIES}`,
  ROUTES: `${RouteName.SETTINGS}${RouteName.ROUTES}`,
  ROUTES_ADD: `${RouteName.SETTINGS}${RouteName.ROUTES}${PathServiceName.ADD}`,
  REQUISITES: `${RouteName.SETTINGS}${RouteName.REQUISITES}`,
  TASKS_DOCUMENTS: `${RouteName.TASKS}${TasksSubMenuItem.DOCUMENTS}`,
  TASKS_MESSAGES: `${RouteName.TASKS}${TasksSubMenuItem.MESSAGES}`,
  USERS: `${RouteName.ADMINISTRATION}${RouteName.USERS}`,
  USERS_ADD: `${RouteName.ADMINISTRATION}${RouteName.USERS}${PathServiceName.ADD}`,
  USERS_INVITES: `${RouteName.ADMINISTRATION}${RouteName.USERS}${RouteName.INVITES}`,
  USERS_INVITES_ADD: `${RouteName.ADMINISTRATION}${RouteName.USERS}${RouteName.INVITES}${PathServiceName.ADD}`,
  DOCUMENTS_ALL: `${RouteName.DOCUMENTS}/${DefaultSubMenuItem.ALL}`,
  DOCUMENTS_IN_PROGRESS: `${RouteName.DOCUMENTS}/${DefaultSubMenuItem.IN_PROGRESS}`,
  DOCUMENTS_ADD: `${RouteName.DOCUMENTS}${PathServiceName.ADD}`,
  DOCUMENTS_FILTERS: `${RouteName.DOCUMENTS}${RouteName.FILTERS}`,
  MESSAGES_OUTGOING: `${RouteName.MESSAGES}/${DefaultSubMenuItem.OUTGOING}`,
  MESSAGES_INCOMING: `${RouteName.MESSAGES}/${DefaultSubMenuItem.INCOMING}`,
  MESSAGES_ADD: `${RouteName.MESSAGES}${PathServiceName.ADD}`
}

export const Path = {
  ACCESS_GROUPS_ALL: Section.ACCESS_GROUPS,
  GROUPS_ALL: Section.GROUPS,
  GROUPS_ADD: Section.GROUPS_ADD,
  GROUPS_EDIT: `${Section.GROUPS}${PathServiceName.ID}`,
  DIVISIONS_ADD: Section.DIVISIONS_ADD,
  DIVISIONS_ADD_CHILD: `${Section.DIVISIONS_ADD}${PathServiceName.PARENT}`,
  DIVISIONS_EDIT: `${Section.DIVISIONS}${PathServiceName.ID}`,
  DIVISIONS_ALL: Section.DIVISIONS,
  USERS_ADD: Section.USERS_ADD,
  USERS_EDIT: `${Section.USERS}${PathServiceName.ID}`,
  USERS_ALL: Section.USERS,
  USERS_INVITES_ADD: Section.USERS_INVITES_ADD,
  USERS_INVITES_EDIT: `${Section.USERS_INVITES}${PathServiceName.ID}`,
  USERS_INVITES_ALL: Section.USERS_INVITES,
  ROUTES_ALL: Section.ROUTES,
  ROUTES_ADD: Section.ROUTES_ADD,
  ROUTES_EDIT: `${Section.ROUTES}${PathServiceName.ID}`,
  HANDBOOKS_ALL: Section.DICTIONARIES,
  CONSTRAINTS_ALL: Section.CONSTRAINTS,
  CONSTRAINTS_ADD: Section.CONSTRAINTS_ADD,
  CONSTRAINTS_EDIT: `${Section.CONSTRAINTS}${PathServiceName.ID}`,
  CONTRACTORS_ALL: RouteName.CONTRACTORS,
  CONTRACTORS_ADD: Section.CONTRACTORS_ADD,
  CONTRACTORS_EDIT: `${RouteName.CONTRACTORS}${PathServiceName.ID}`,
  DOCUMENT_TYPES_ADD: `${Section.DOCUMENT_TYPES}${PathServiceName.ADD}`,
  DOCUMENT_TYPES_ALL: Section.DOCUMENT_TYPES,
  DOCUMENT_TYPES_EDIT: `${Section.DOCUMENT_TYPES}${PathServiceName.ID}`,
  FIELDS_ADD: `${Section.FIELDS}${PathServiceName.ADD}`,
  FIELDS_ALL: Section.FIELDS,
  FIELDS_EDIT: `${Section.FIELDS}${PathServiceName.ID}`,
  FILTERS: `${RouteName.DOCUMENTS}${RouteName.FILTERS}${PathServiceName.ID}`,
  DOCUMENTS_EDIT: `${RouteName.DOCUMENTS}${PathServiceName.ID}`,
  DOCUMENTS_ADD: `${RouteName.DOCUMENTS}${PathServiceName.ADD}`,
  DOCUMENTS_IN_PROGRESS: Section.DOCUMENTS_IN_PROGRESS,
  DOCUMENTS_ALL: Section.DOCUMENTS_ALL,
  RECEIPTS: `${RouteName.MESSAGES}${RouteName.RECEIPTS}`,
  MESSAGES_ADD: `${RouteName.MESSAGES}${PathServiceName.ADD}`,
  MESSAGES_EDIT: `${RouteName.MESSAGES}${PathServiceName.ID}`,
  MESSAGES_OUTGOING: Section.MESSAGES_OUTGOING,
  MESSAGES_INCOMING: Section.MESSAGES_INCOMING,
  ORGS: RouteName.ORGS,
  FORGOT: RouteName.FORGOT,
  SIGN_IN: RouteName.SIGN_IN,
  SIGN_UP: RouteName.SIGN_UP,
  SSO_AUTH: RouteName.SSO_AUTH,
  PROFILE: RouteName.PROFILE,
  ORGANIZATION_ROUTE: `${RouteName.ORGANIZATION_URL}${PathServiceName.ASTERISK_SYMBOL}`,
  UTD_ADD: `${RouteName.UTD}${PathServiceName.ADD}`,
  REQUISITES: Section.REQUISITES,
  APPLICATIONS: `${RouteName.APPLICATIONS}`,
  APPLICATION_VIEW: `${RouteName.APPLICATIONS}${PathServiceName.ID}`
}

export enum TagNames {
  TR = 'TR'
}

export enum FormProperty {
  CARD = 'form',
  LIST = 'list'
}

export enum SignatureCheckStatus {
  INVALID = 'INVALID_SIGNATURE',
  NOT_ACCEPTABLE = 'NOT_ACCEPTABLE',
  VALID = 'VALID_SIGNATURE',
  WAITING = 'WAITING_FOR_CHECK'
}

export const fileSizeLimitBytes = 1.073741824e+9

export const logoSizeLimitBytes = 1e+5

export const ACCESS_MODE_OPTIONS = [AccessMode.INITIATOR, AccessMode.BLOCKED, AccessMode.ALLOWED]

export const APPLY_TO_EXTERNAL_OPTIONS = [
  ApplyToExternal.NOT_APPLY_TO_EXTERNAL,
  ApplyToExternal.APPLY_TO_EXTERNAL,
  ApplyToExternal.APPLY_ONLY_TO_EXTERNAL
]

export const FIELD_TYPE_OPTIONS = [
  FieldType.NUMBER,
  FieldType.STRING,
  FieldType.DATE,
  FieldType.DATETIME,
  FieldType.BOOLEAN,
  FieldType.DICTIONARY,
  FieldType.FILE
]

export const TaskResultColor = {
  APPROVED: 'text-success',
  REJECTED: 'text-danger',
  CANCELED: 'text-danger',
  REASSIGNED: 'text-warning',
  TODO: 'text-success',
  SIGNED: 'text-success'
}

export const FlowStateStatus = {
  WAITING: 'WAITING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  SIGNED: 'SIGNED',
  CANCELED: 'CANCELED'
}

export enum StagesType {
  APPROVAL = 'APPROVAL',
  SIGNING = 'SIGNING'
}

export const StageTypes: { [key in StageTypeKeys]: { label: ILibLabelProps['status']; title: string } } = {
  APPROVAL: {
    label: 'primary',
    title: Stage.approval
  },
  ACQUAINTANCE: {
    label: 'primary',
    title: Stage.approval
  },
  SIGNING: {
    label: 'success',
    title: Stage.signing
  }
}

export enum FilterName {
  DIRECTION_EQ = 'direction.eq',
  DOCUMENT_ID_IN = 'documentId.in',
  FLOW_STATE_EQ = 'flowState.eq',
  GROUP_OGUID_NE = 'groupOguid.ne',
  IS_VALIDATED_EQ = 'isValidated.eq',
  QUERY = 'query',
  QUERY_LIKE = 'query.like',
  SHOW_MODE = 'showMode',
  IS_ACTIVATED = 'isActivated.eq',
  VALUE_LIKE = 'value.like'
}

export enum FilterValue {
  OUTGOING = 'OUTGOING',
  INCOMING = 'INCOMING',
  WAITING_SENDER_SIGNATURE = 'WAITING_SENDER_SIGNATURE',
  WAITING_TO_SEND = 'WAITING_TO_SEND',
  WAITING_TO_SEND_PROXY = 'WAITING_TO_SEND_PROXY',
  WAITING_FOR_RECEIPT = 'WAITING_FOR_RECEIPT',
  WAITING_PROXY_SIGNATURE = 'WAITING_PROXY_SIGNATURE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  ERROR_IN_PROXY = 'ERROR_IN_PROXY',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  PROCESSED = 'PROCESSED',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
  SIGNATURE_REJECTED = 'SIGNATURE_REJECTED',
  INTERNAL = 'INTERNAL'
}

export enum KeyCode {
  ENTER = 13,
  ESC = 27,
  SEMICOLON = 186,
  TAB = 9,
  SPACE = 32
}

export enum Key {
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ENTER = 'Enter',
  ESC = 'Escape'
}

export enum RecipientShowMode {
  ALL = 'ALL',
  GROUPS = 'GROUPS',
  USERS = 'USERS'
}

export enum FlowStage {
  SIGNED = 'SIGNING',
  APPROVAL = 'APPROVAL'
}

export enum AllowedAction {
  APPROVE = 'APPROVE',
  COPY = 'COPY',
  CORRECTION_REQUEST = 'CORRECTION_REQUEST',
  DECLINE_APPROVE = 'DECLINE_APPROVE',
  DECLINE_SIGN = 'DECLINE_SIGN',
  DELETE = 'DELETE',
  DELETE_INVITE = 'DELETE_INVITE',
  DOWNLOAD_DOCUMENTS = 'DOWNLOAD_DOCUMENTS',
  DOWNLOAD_DOCUMENTS_FLOW = 'DOWNLOAD_DOCUMENTS_FLOW',
  DOWNLOAD_REGISTRY = 'DOWNLOAD_REGISTRY',
  FLOW_EDIT = 'FLOW_EDIT',
  MODIFY = 'MODIFY',
  NEW_VERSION = 'NEW_VERSION',
  SIGN = 'SIGN',
  SIGN_TICKET = 'SIGN_TICKET',
  SIGN_TITLE = 'SIGN_TITLE',
  START_PROCESS = 'START_PROCESS',
  STOP_PROCESS = 'STOP_PROCESS',
  RESEND = 'RESEND'
}

export const actionButtonTheme: IActionButtonTheme = {
  APPROVE: 'warning',
  CORRECTION_REQUEST: 'primary',
  DECLINE_APPROVE: 'danger',
  DECLINE_SIGN: 'danger',
  DELETE: 'danger',
  MODIFY: 'primary',
  SIGN: 'success',
  SIGN_TICKET: 'success',
  SIGN_TITLE: 'success',
  START_PROCESS: 'info',
  STOP_PROCESS: 'danger'
}

export const UserActionType = {
  APPROVE: { name: 'IconSuccess', iconProps: {} },
  ADD_TO_PACKAGE: { name: 'IconPlus', iconProps: {} },
  DECLINE_APPROVE: { name: 'IconCancelled', iconProps: {} },
  DECLINE_SIGN: { name: 'IconCancelled', iconProps: {} },
  DELETE: { name: 'IconTrash', iconProps: {} },
  MODIFY: { name: 'IconEditAlt', iconProps: {} },
  SIGN: { name: 'IconCert', iconProps: {} },
  SIGN_TICKET: { name: 'IconCert', iconProps: {} },
  SIGN_TITLE: { name: 'IconCert', iconProps: {} },
  START_PROCESS: { name: 'IconPlay', iconProps: { color: 'primary-500' } },
  STOP_PROCESS: { name: 'IconStop', iconProps: {} }
}

export enum TaskStatus {
  SOLVED = 'SOLVED',
  DECLINED = 'DECLINED'
}

export enum DocumentType {
  NONFORMALIZED = 'NONFORMALIZED'
}

export enum Theme {
  AUTH = 'theme-auth',
  DEFAULT = 'theme-default'
}

export enum Operator {
  EQ = 'eq',
  GE = 'ge',
  GT = 'gt',
  IN = 'in',
  IS_NOT_NULL = 'isnotnull',
  IS_NULL = 'isnull',
  LE = 'le',
  LIKE = 'like',
  LT = 'lt',
  NE = 'ne'
}

export type SumTableConditionOperatior = Omit<Operator, 'in' | 'isnotnull' | 'isnull' | 'like'>

export const STAGE_TYPE_OPTIONS = [StagesType.APPROVAL, StagesType.SIGNING]

export enum BooleanValue {
  FALSE = 'false',
  TRUE = 'true'
}

const statusIconProps: ILibIcon = {
  externalClass: 'flex-shrink-0 mr-1',
  size: 'xs'
}

export const StatusIcons = {
  [StatusIcon.ERROR]: <IconDisk {...statusIconProps} color='danger-500' />,
  [StatusIcon.INFO]: <IconInfo {...statusIconProps} color='info-500' />,
  [StatusIcon.PROGRESS]: <IconInProgress {...statusIconProps} color='warning-500' />,
  [StatusIcon.SUCCESS]: <IconSuccess {...statusIconProps} color='success-500' />,
  [StatusIcon.UNKNOWN]: <IconWaiting {...statusIconProps} color='gray-500' />,
  [StatusIcon.WARNING]: <IconDisk {...statusIconProps} color='warning-500' />
}

export const relatedDocsFileNameLength = 18

export enum BrowserStorage {
  ACTIVE_ORGANIZATION = 'active_organization',
  CERTIFICATES = 'certificates',
  SELECTED_CERTIFICATES = 'selected_certificates',
  CONTRACTORS_FILTERS = 'contractors_filters',
  DOCUMENTS_FILTERS = 'documents_filters',

  INVITES_FILTERS = 'invites_filters',
  MESSAGES_FILTERS = 'messages_filters',
  USERS_FILTERS = 'users_filters',
  TASKS_FILTERS = 'tasks_filters',
  DOC_LAST_URL = 'doc_last_url',
  LANGUAGE = 'i18nextLng',
  TOKEN_CLIENT = 't_client',
  TOKEN_IS_REMEMBER = 't_is_remember',
  TOKEN_ACCESS = 't_access',
  TOKEN_FEDERATION = 't_federation',
  TOKEN_FILE = 't_file',
  TOKEN_REFRESH = 't_refresh',
  TOKEN_TAB = 't_tab',
  TOKEN_TABS = 't_tabs',
  TOKEN_USER = 't_user',
  INSTANCE = 'instance',
  THUMBNAILS_STATE = 'thumbnails_state'
}

export const LocalStorageKeysForSaveBetweenSessions = [
  BrowserStorage.CERTIFICATES,
  BrowserStorage.CONTRACTORS_FILTERS,
  BrowserStorage.DOCUMENTS_FILTERS,
  BrowserStorage.MESSAGES_FILTERS,
  BrowserStorage.USERS_FILTERS,
  BrowserStorage.LANGUAGE,
  BrowserStorage.TOKEN_TABS,
  BrowserStorage.INSTANCE,
  BrowserStorage.TOKEN_CLIENT
]

export const SessionStorageKeysForSaveBetweenSessions: string[] = [
  BrowserStorage.TOKEN_TAB
]

// TODO collect the rest of the modal IDs here
export enum Modal {
  ACCESS_GROUP = 'accessGroup',
  ADD_FIELD = 'addField',
  ADD_DOCUMENT_RELATIONS = 'addDocumentRelations',
  ADD_GROUP_MEMBER = 'AddGroupMember',
  ADD_TABLE_FIELD = 'addTableField',
  AUTO_DOCUMENT_NUMBER = 'autoDocumentNumber',
  CHANGE_PASSWORD = 'ChangePassword',
  CONSTRAINT_VIOLATIONS = 'ConstraintViolations',
  DIVISION = 'modalDivision',
  DOCUMENT_TYPE_GROUP = 'DocumentTypeGroup',
  DOCUMENTS_DOWNLOAD = 'DocumentsDownload',
  EXISTING_USER = 'ExistingUser',
  EXTENDED_SIGNER = 'ExtendedSigner',
  EXPORT = 'export',
  FIELD_SPLITTER = 'fieldSplitter',
  FLOW_EDIT = 'FlowEdit',
  HANDBOOK_ENTRY = 'HandbookEntry',
  HANDBOOK_LINK = 'HandbookLink',
  MASSIVE_ACTION = 'MassiveAction',
  MASSIVE_SIGN_MESSAGE = 'MassiveSignMessage',
  NEW_ORGANIZATION = 'NewOrganization',
  NEW_VERSION_FLOW = 'NewVersionFlow',
  ORGANIZATION_DELETED = 'OrganizationDeleted',
  REGISTRY_DOWNLOAD = 'registryDownload',
  REQUEST_SIGN = 'requestSign',
  SIGN = 'modalSign',
  SIGNATURE_INFO = 'signatureInfo',
  USER_CERTIFICATE = 'userCertificate',
  USER_CERTIFICATE_ISSUE = 'userCertificateIssue',
  USER_CERTIFICATE_REQUEST = 'userCertificateRequest',
  USER_CERTIFICATE_REVOKE = 'userCertificateRevoke',
  USER_ERRORS = 'userErrors',
  USER_FILTERS = 'userFilters',
  INTEGRATION_OPTIONS = 'integrationOptions',
  DEPENDENT_FIELDS = 'dependentFields',
  CERTIFICATE_POWER = 'certificatePower',
  CERTIFICATE_SETTINGS = 'certificateSettings',
  FILE_VERSIONS = 'fileVersions',
  PREPROCESSING_SETTINGS = 'preProcessingSettings',
  IP_RESTRICTION = 'ipRestriction',
}

export enum GroupField {
  CHECKBOX_ALL_MEMBERS = 'checkboxAllMembers',
  CHECKBOX_MEMBER = 'checkboxMember',
  INITIAL_NAME = 'initialName',
  MEMBERS = 'members',
  NAME = 'name',
  NEW_MEMBER = 'newMember',
  OGUID = 'oguid',
  SELECTED_MEMBERS = 'selectedMembers',
  USERS_COUNT = 'usersCount'
}

// enum is not suitable for the role model
export const Role = {
  USER: 'USER',
  ORGANIZATION: 'ORGANIZATION',
  ADMINISTRATOR: 'ADMINISTRATOR'
}

export const AllRoles = [Role.USER, Role.ORGANIZATION, Role.ADMINISTRATOR]

export const RoleModelRestrictionKey = {
  IS_FLOW_FUNCTIONALITY_ENABLED: 'isFlowFunctionalityEnabled',
  IS_EXCHANGE_PARTICIPANT: 'isExchangeParticipant',
  IS_READONLY: 'isReadonly'
}

export const RoleModelRestrictionKeys = [
  RoleModelRestrictionKey.IS_FLOW_FUNCTIONALITY_ENABLED,
  RoleModelRestrictionKey.IS_EXCHANGE_PARTICIPANT,
  RoleModelRestrictionKey.IS_READONLY
]

// filters require enum
export enum UserRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ORGANIZATION = 'ORGANIZATION',
  USER = 'USER'
}

export const AllUserRoles = [UserRole.USER, UserRole.ORGANIZATION, UserRole.ADMINISTRATOR]

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED'
}

export const AllUserStatuses = [UserStatus.ACTIVE, UserStatus.BLOCKED]

export const BooleanValues = [BooleanValue.TRUE, BooleanValue.FALSE]

export const AllFlowStates = [FlowState.NOT_STARTED, FlowState.IN_PROGRESS, FlowState.COMPLETED]

export const AllDirections = [DirectionValue.INCOMING, DirectionValue.OUTGOING, DirectionValue.INTERNAL]

export const AvailableDirections = [DirectionValue.INCOMING, DirectionValue.OUTGOING]

export const AvailableIsRead = [BooleanValue.TRUE, BooleanValue.FALSE]

export enum IsFederationUser {
  FEDERATION = "FEDERATION",
  NON_FEDERATION = "NON_FEDERATION"
}

export const AllIsFederationUser = [IsFederationUser.FEDERATION, IsFederationUser.NON_FEDERATION]

export enum FlowResult {
  APPROVED = 'APPROVED',
  APPROVING = 'APPROVING',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DECLINED = 'DECLINED',
  ERROR = 'ERROR',
  REJECTED = 'REJECTED',
  SIGNATURE_REJECTED = 'SIGNATURE_REJECTED',
  SIGNED = 'SIGNED',
  SOLVED = 'SOLVED',
  STOPPED = 'STOPPED'
}

export const AllFlowResults = [
  FlowResult.APPROVING,
  FlowResult.APPROVED,
  FlowResult.CLOSED,
  FlowResult.DECLINED,
  FlowResult.ERROR,
  FlowResult.REJECTED,
  FlowResult.SIGNATURE_REJECTED,
  FlowResult.SIGNED,
  FlowResult.SOLVED
]

export const FilterFlowResults = [FlowResult.DECLINED, FlowResult.ERROR, FlowResult.SOLVED]

export enum WorkflowStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  NEED_TO_PROCESS = 'NEED_TO_PROCESS',
  WAITING_FOR_CONTRACTOR = 'WAITING_FOR_CONTRACTOR'
}

export const AllWorkflowStatuses = [
  WorkflowStatus.NEED_TO_PROCESS,
  WorkflowStatus.WAITING_FOR_CONTRACTOR,
  WorkflowStatus.SUCCESS,
  WorkflowStatus.ERROR
]

export enum FlowStatus {
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  SUCCESS = 'SUCCESS'
}

export const AllFlowStatuses = [FlowStatus.NOT_STARTED, FlowStatus.IN_PROGRESS, FlowStatus.SUCCESS, FlowStatus.ERROR]

export const AllPreProcessingStatuses = [IPreProcessingJobStatus.COMPLETED, IPreProcessingJobStatus.IN_PROGRESS, IPreProcessingJobStatus.IN_QUEUE, IPreProcessingJobStatus.ERROR, IPreProcessingJobStatus.NOT_APPLICABLE]

/**
 * Used in situations when we don't want an error in the request to lead to a redirect to a 404 page.
 * for example, when loading data for select options
 */
export const needResetParams: IParams = { isNeedResetError: true }

export enum MassiveActionPage {
  DOCUMENTS = 'documents',
  INVITES = 'invites',
  MESSAGES = 'messages',
  RECEIPTS = 'receipts',
  TASKS = 'tasks'
}

export enum UserField {
  ACCESSIBLE_SUB_ORG = 'accessibleSubOrgOguids',
  CERTIFICATE = 'certificate',
  CERTIFICATE_REQUEST = 'docuForceCertificateRequest',
  CODE = 'code',
  DOCUMENT_TYPE_ACCESS_GROUP = 'documentTypeAccessGroup',
  DOCUMENT_TYPE_ACCESS_GROUP_OGUID = 'documentTypeAccessGroupOguid',
  EMAIL = 'email',
  EXISTING_USER = 'existingUser',
  EXTENDED_SIGNER = 'extendedSigner',
  FAKE_LOGIN = 'fakeLogin',
  FEDERATION_OGUID = 'federationOguid',
  FLOW_CHANGE_NOTIFICATION = 'flowChangeNotification',
  IS_NEW_MESSAGE_NOTIFICATION = 'isNewMessageNotification',
  IS_NEW_PATCH_NOTIFICATION = 'isNewPatchNotification',
  IS_NOTIFICATION_ENABLED = 'isNotificationEnabled',
  IS_NOTIFY_BY_EMAIL = 'isNotifyByEmail',
  IS_NOTIFY_BY_MOBILE = 'isNotifyByMobile',
  IS_DAILY_NOTIFICATION_UNREAD_TASK = 'isDailyNotificationUnreadTask',
  ADDITIONAL_INFO_1 = 'additionalInfo1',
  ADDITIONAL_INFO_2 = 'additionalInfo2',
  IS_READONLY = 'isReadonly',
  LOCALE = 'locale',
  LOGIN = 'login',
  NAME = 'name',
  OGUID = 'oguid',
  ROLE = 'role',
  ROLE_RESTRICIONS = 'roleRestrictions',
  PATRONYMIC = 'patronymic',
  PASSWORD = 'password',
  PHONE = 'phone',
  POSITION = 'position',
  SCOPE = 'scope',
  SEND_INVITE = 'sendInvite',
  SIGNATURE_PARAM = 'docuforceSignatureParam',
  SIGNATURE_PARAM_APPROVE_SECOND_FACTOR = 'docuforceSignatureParam.approveSecondFactor',
  SIGNATURE_PARAM_IS_REQUEST_BY_SMS = 'docuforceSignatureParam.isRequestBySms',
  STATUS = 'status',
  SUB_ORG = 'subOrg',
  SUB_ORG_OGUID = 'subOrgOguid',
  SURNAME = 'surname',
  TIMEZONE = 'timeZone'
}

export enum UserScope {
  ORG = 'ORG',
  OTHER_SUB_ORGS = 'OTHER_SUB_ORGS',
  SUB_ORG = 'SUB_ORG',
  SUB_ORG_AND_BELOW = 'SUB_ORG_AND_BELOW'
}

export const AllUserScopes = [UserScope.ORG, UserScope.SUB_ORG_AND_BELOW, UserScope.OTHER_SUB_ORGS, UserScope.SUB_ORG]

export enum UserReadonly {
  FALSE = 'false',
  TRUE = 'true'
}

export const UserReadonlyOptions = [UserReadonly.FALSE, UserReadonly.TRUE]

export enum SignatureSignerPowers {
  INVOICE_SIGNER = 'INVOICE_SIGNER',
  PERSON_MADE_OPERATION = 'PERSON_MADE_OPERATION',
  MADE_AND_SIGN_OPERATION = 'MADE_AND_SIGN_OPERATION',
  PERSON_DOCUMENTED_OPERATION = 'PERSON_DOCUMENTED_OPERATION',
  MADE_OPERATION_AND_SIGNED_INVOICE = 'MADE_OPERATION_AND_SIGNED_INVOICE',
  MADE_AND_RESPONSIBLE_FOR_OPERATION_AND_SIGNED_INVOICE = 'MADE_AND_RESPONSIBLE_FOR_OPERATION_AND_SIGNED_INVOICE',
  RESPONSIBLE_FOR_OPERATION_AND_SIGNER_FOR_INVOICE = 'RESPONSIBLE_FOR_OPERATION_AND_SIGNER_FOR_INVOICE'
}

export const SIGNATURE_SIGNER_POWERS_BUYER_OPTIONS = [
  SignatureSignerPowers.PERSON_MADE_OPERATION,
  SignatureSignerPowers.MADE_AND_SIGN_OPERATION,
  SignatureSignerPowers.PERSON_DOCUMENTED_OPERATION
]

export const SIGNATURE_SIGNER_POWERS_SELLER_OPTIONS = [
  SignatureSignerPowers.INVOICE_SIGNER,
  SignatureSignerPowers.PERSON_MADE_OPERATION,
  SignatureSignerPowers.MADE_AND_SIGN_OPERATION,
  SignatureSignerPowers.PERSON_DOCUMENTED_OPERATION,
  SignatureSignerPowers.MADE_OPERATION_AND_SIGNED_INVOICE,
  SignatureSignerPowers.MADE_AND_RESPONSIBLE_FOR_OPERATION_AND_SIGNED_INVOICE,
  SignatureSignerPowers.RESPONSIBLE_FOR_OPERATION_AND_SIGNER_FOR_INVOICE
]

export enum ExtendedSignerField {
  IS_EMPTY = 'isEmpty',
  SIGNATURE_SIGNER_INFO = 'signatureSignerInfo',
  SIGNATURE_SIGNER_ORG_POWERS_BASE = 'signatureSignerOrgPowersBase',
  SIGNATURE_SIGNER_POWERS_BASE = 'signatureSignerPowersBase',
  SIGNATURE_SIGNER_POWERS_BUYER = 'signatureSignerPowersBuyer',
  SIGNATURE_SIGNER_POWERS_SELLER = 'signatureSignerPowersSeller',
  SIGNATURE_SIGNER_STATUS = 'signatureSignerStatus',
  SIGNATURE_SIGNER_TYPE = 'signatureSignerType'
}

export enum SignatureSignerStatus {
  EMPLOYEE = 'EMPLOYEE',
  INFORMATION_CREATOR_EMPLOYEE = 'INFORMATION_CREATOR_EMPLOYEE',
  OTHER_ORGANIZATION_EMPLOYEE = 'OTHER_ORGANIZATION_EMPLOYEE',
  AUTHORIZED_PERSON = 'AUTHORIZED_PERSON'
}

export const SIGNATURE_SIGNER_STATUS_OPTIONS = [
  SignatureSignerStatus.EMPLOYEE,
  SignatureSignerStatus.INFORMATION_CREATOR_EMPLOYEE,
  SignatureSignerStatus.OTHER_ORGANIZATION_EMPLOYEE,
  SignatureSignerStatus.AUTHORIZED_PERSON
]

export enum SignatureSignerType {
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  INDIVIDUAL_ENTITY = 'INDIVIDUAL_ENTITY',
  PHYSICAL_PERSON = 'PHYSICAL_PERSON'
}

export const SIGNATURE_SIGNER_TYPE_OPTIONS = [
  SignatureSignerType.LEGAL_ENTITY,
  SignatureSignerType.INDIVIDUAL_ENTITY,
  SignatureSignerType.PHYSICAL_PERSON
]

export enum FlowChangeNotification {
  ANY_CHANGES = 'ANY_CHANGES',
  FINISHED_ONLY = 'FINISHED_ONLY',
  NONE = 'NONE'
}

export const FLOW_CHANGE_NOTIFICATION_OPTIONS = [
  FlowChangeNotification.NONE,
  FlowChangeNotification.ANY_CHANGES,
  FlowChangeNotification.FINISHED_ONLY
]

export const UserLoginLength = {
  MIN: 3,
  MAX: 50
}

export const UserInitialsLength = {
  MIN: 1,
  MAX: 50
}

export const UserEmailLength = {
  MAX: 150
}

export const UserNameLength = {
  MIN: 1,
  MAX: 2000
}

export const UserSurnameLength = {
  MIN: 1,
  MAX: 2000
}

export const UserPatronymicLength = {
  MIN: 1,
  MAX: 2000
}

export const UserPasswordLength = {
  MIN: 8,
  MAX: 50
}

export const AliasLength = {
  MIN: 6,
  MAX: 30
}

export const OrgOgrnLength = {
  MIN: 6,
  MAX: 30
}

export const OrgNameLength = {
  MIN: 4,
  MAX: 2000
}

export const OrgNameShortLength = {
  MIN: 4,
  MAX: 2000
}

export const ForeignAddressLength = {
  MAX: 1000,
  MIN: 4
}

export const ForeignCountryLength = {
  MAX: 50,
  MIN: 2
}

export const ContactsLength = {
  MAX: 50
}

export const HandbookKeyLength = {
  MAX: 50,
  MIN: 1
}

export const HandbookNameLength = {
  MAX: 50,
  MIN: 1
}

export const HandbookValueLength = {
  MAX: 1000,
  MIN: 1
}

export enum RequisitesField {
  ADDR_TEXT = 'addrText',
  ALIAS = 'alias',
  FNS_PARTICIPANT_CODE = 'fnsParticipantCode',
  FOREIGN_ADDRESS = 'foreignAddress',
  FOREIGN_COUNTRY = 'foreignCountry',
  INN = 'inn',
  IS_EXCHANGE_PARTICIPANT = 'isExchangeParticipant',
  IS_FLOW_FUNCTIONALITY_ENABLED = 'isFlowFunctionalityEnabled',
  IS_FOREIGN = 'isForeign',
  KPP = 'kpp',
  NAME = 'name',
  NAME_SHORT = 'nameShort',
  OGRN = 'ogrn'
}

export enum MessagesField {
  ASSIGNED_TO_USER_OGUID = 'assignedToUserOguid',
  DESCRIPTION = 'description'
}

/**
 * An array for ordering the available IAllowedActions for a document card, actions:
 * - MODIFY,
 * - NEW_VERSION,
 * - DELETE,
 * - SIGN_TICKET,
 * - CORRECTION_REQUEST
 * removed due to lack of implementation or other reasons
 */
export const TEMPLATE_DOCUMENT_ACTIONS: IAllowedAction[] = [
  AllowedAction.START_PROCESS,
  AllowedAction.APPROVE,
  AllowedAction.DECLINE_APPROVE,
  AllowedAction.SIGN,
  AllowedAction.SIGN_TITLE,
  AllowedAction.DECLINE_SIGN
]

/**
 * An array for ordering the available IAllowedActions for view Documents, actions:
 * - MODIFY,
 * - DELETE,
 * - STOP_PROCESS
 * - SIGN_TICKET,
 * removed due to lack of implementation or other reasons
 */
export const TEMPLATE_DOCUMENTS_ACTIONS: IAllowedAction[] = [
  AllowedAction.START_PROCESS,
  AllowedAction.APPROVE,
  AllowedAction.DECLINE_APPROVE,
  AllowedAction.SIGN,
  AllowedAction.SIGN_TITLE,
  AllowedAction.DECLINE_SIGN
]

/**
 * An array for ordering the available IAllowedActions for view Tasks, actions:
 * - MODIFY,
 * - DELETE,
 * - STOP_PROCESS
 * - SIGN_TICKET,
 * removed due to lack of implementation or other reasons
 */
export const TEMPLATE_TASKS_ACTIONS: IAllowedAction[] = [
  AllowedAction.START_PROCESS,
  AllowedAction.APPROVE,
  AllowedAction.DECLINE_APPROVE,
  AllowedAction.SIGN,
  AllowedAction.SIGN_TITLE,
  AllowedAction.DECLINE_SIGN
]

export enum MouseEventType {
  AUXCLICK = 'auxclick',
  CLICK = 'click'
}

export enum WindowEventType {
  BEFORE_UNLOAD = 'beforeunload',
}

export enum ProfileField {
  EMAIL = 'email',
  LOGIN = 'login',
  NAME = 'name',
  PATRONYMIC = 'patronymic',
  PASSWORD = 'password',
  PHONE = 'phone',
  SURNAME = 'surname',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
  LOCALE = 'locale',
  TIMEZONE = 'timeZone',
  MENU = 'menu'
}

export enum PhoneVerificationValues {
  IS_PHONE_CONFIRMED = 'isPhoneConfirmed',
  PHONE_CHECK_TRAIN_ID = 'phoneCheckTranID'
}

export enum NewOrganizationMode {
  FOREIGN = 'foreign',
  SIMPLE = 'simple',
  SUGGEST = 'suggest'
}

export enum MessageTab {
  CLOSED = 'closed',
  OPEN = 'open'
}

export enum NoticeCategory {
  NEED_TO_ACTION = 'NEED_TO_ACTION',
  INFO = 'INFO'
}

export enum IgnoredClass {
  DEFAULT = 'ignore-react-onclickoutside',
  MESSAGES = 'messages_toggle',
  SIDEBAR = 'sidebar_toggle'
}

export const Alias = {
  MAX_LENGTH: AliasLength.MAX,
  ORG_TYPE_REGEXP: /^[ЗО]?[АО]О\s/g,
  SPACE_REPLACER: '_',
  SYMBOLS_REGEXP: /["«»'’()]/g
}

export const DebounceDelay = {
  ORGANIZATIONS: 200,
  ACCESS_GROUPS: 200
}

export enum HandbookField {
  KEY = 'key',
  NAME = 'name',
  VALUE = 'value'
}

export enum HandbookFormPrefix {
  HANDBOOK = 'handbook',
  LINK = 'link'
}

export const DOWNLOAD_DEBOUNCE = 300

export enum RouteField {
  ASSIGN_MODE = 'assignMode',
  ASSIGNED_TO_GROUP = 'assignedToGroup',
  ASSIGNED_TO_GROUP_OGUID = 'assignedToGroupOguid',
  ASSIGNED_TO_USER = 'assignedToUser',
  ASSIGNED_TO_USER_OGUID = 'assignedToUserOguid',
  ASSIGNED_TO_CALCULATED_USER = 'assignedToCalculatedUser',
  ASSIGNED_TO_MANAGER_OF_SUBORG = 'assignedToManagerOfSubOrg',
  ASSIGNED_TO_MANAGER_OF_SUBORG_OGUID = 'assignedToManagerOfSubOrgOguid',
  ASSIGNEE = 'assignee',
  ASSIGNEE_ACTION_TYPE = 'assigneeActionType',
  ASSIGNEES = 'assignees',
  ASSIGNEES_ID_LIST = 'assigneesIdList',
  AUTO_REASSIGN_TO_GROUP = 'autoReassignToGroup',
  AUTO_REASSIGN_TO_GROUP_OGUID = 'autoReassignToGroupOguid',
  AUTO_REASSIGN_TO_MANAGER_OF_SUBORG = 'autoReassignToManagerOfSubOrg',
  AUTO_REASSIGN_TO_MANAGER_OF_SUBORG_OGUID = 'autoReassignToManagerOfSubOrgOguid',
  AUTO_REASSIGN_TO_OWNER = 'autoReassignToOwner',
  AUTO_REASSIGN_TO_USER = 'autoReassignToUser',
  AUTO_REASSIGN_TO_USER_OGUID = 'autoReassignToUserOguid',
  CONDITIONS = 'conditions',
  CONTRACTOR = 'contractor',
  DOCUMENT_TYPES = 'documentTypes',
  DOCUMENT_TYPES_CHECKED = 'documentTypesChecked',
  DEADLINE = 'deadline',
  HINT = 'hint',
  HOURS_TO_PROCESS = 'hoursToProcess',
  HOURS_TO_AUTO_ACTION = 'hoursToAutoAction',
  OWNER_USER_OGUID = 'ownerUserOguid',
  OWNER_GROUP_OGUID = 'ownerGroupOguid',
  OWNER_MANAGER_OF_SUBORG_OGUID = 'ownerManagerOfSubOrgOguid',
  OWNER_CALCULATED_USER = 'ownerCalculatedUser',
  STAGE_TYPE = 'stageType',
  STAGES = 'stages',
  STAGES_ID_LIST = 'stagesIdList',
  START_CONDITIONS = 'startConditions',
  SUB_ORG = 'subOrg',
  TYPE = 'type',
  USER_NAME = 'userName'
}

export enum DocumentSaveType {
  APPROVE_AND_SAVE = 'approveAndSave',
  APPROVE_AND_SEND = 'approveAndSend',
  REQUEST_SIGN = 'requestSign',
  SAVE = 'save',
  SEND_FOR_APPROVE = 'sendForApprove',
  SEND_FOR_SIGN = 'sendForSign',
  START_PROCESS = 'startProcess',
  SIGN_AND_SAVE = 'signAndSave',
  SIGN_AND_SEND = 'signAndSend'
}

export const ALL_FILTER = 'all'

export enum AttachmentType {
  TICKET_RECEIPT = 'TICKET_RECEIPT',
  UNIVERSAL_TRANSFER_DOCUMENT_BUYER_TITLE = 'UNIVERSAL_TRANSFER_DOCUMENT_BUYER_TITLE',
  INVOICE_CORRECTION_REQUEST = 'INVOICE_CORRECTION_REQUEST',
  SIGNATURE_REQUEST_REJECTION = 'SIGNATURE_REQUEST_REJECTION'
}

export enum FilterMode {
  /** Default mode - main filters are always displayed, additional - only used ones are displayed */
  DEFAULT = 'default',
  /** Alternative mode - only used main filters are shown */
  /** Now this mode is not used anywhere */
  SHORT = 'short'
}

export const FILTER_MODE: FilterMode = FilterMode.DEFAULT

export const CurrencyMap = {
  104: 'MMK',
  108: 'BIF',
  116: 'KHR',
  124: 'CAD',
  132: 'CVE',
  136: 'KYD',
  144: 'LKR',
  152: 'CLP',
  156: 'CNY',
  170: 'COP',
  174: 'KMF',
  188: 'CRC',
  191: 'HRK',
  192: 'CUP',
  203: 'CZK',
  208: 'DKK',
  214: 'DOP',
  222: 'SVC',
  230: 'ETB',
  232: 'ERN',
  238: 'FKP',
  242: 'FJD',
  262: 'DJF',
  270: 'GMD',
  292: 'GIP',
  320: 'GTQ',
  324: 'GNF',
  328: 'GYD',
  332: 'HTG',
  340: 'HNL',
  344: 'HKD',
  348: 'HUF',
  352: 'ISK',
  356: 'INR',
  360: 'IDR',
  364: 'IRR',
  368: 'IQD',
  376: 'ILS',
  388: 'JMD',
  392: 'JPY',
  398: 'KZT',
  400: 'JOD',
  404: 'KES',
  408: 'KPW',
  410: 'KRW',
  414: 'KWD',
  417: 'KGS',
  418: 'LAK',
  422: 'LBP',
  426: 'LSL',
  430: 'LRD',
  434: 'LYD',
  446: 'MOP',
  454: 'MWK',
  458: 'MYR',
  462: 'MVR',
  480: 'MUR',
  484: 'MXN',
  496: 'MNT',
  498: 'MDL',
  504: 'MAD',
  512: 'OMR',
  516: 'NAD',
  524: 'NPR',
  532: 'ANG',
  533: 'AWG',
  548: 'VUV',
  554: 'NZD',
  558: 'NIO',
  566: 'NGN',
  578: 'NOK',
  586: 'PKR',
  590: 'PAB',
  598: 'PGK',
  600: 'PYG',
  604: 'PEN',
  608: 'PHP',
  634: 'QAR',
  643: 'RUB',
  646: 'RWF',
  654: 'SHP',
  682: 'SAR',
  690: 'SCR',
  694: 'SLL',
  702: 'SGD',
  704: 'VND',
  706: 'SOS',
  710: 'ZAR',
  728: 'SSP',
  748: 'SZL',
  752: 'SEK',
  756: 'CHF',
  760: 'SYP',
  764: 'THB',
  776: 'TOP',
  780: 'TTD',
  784: 'AED',
  788: 'TND',
  800: 'UGX',
  807: 'MKD',
  818: 'EGP',
  826: 'GBP',
  834: 'TZS',
  840: 'USD',
  858: 'UYU',
  860: 'UZS',
  882: 'WST',
  886: 'YER',
  901: 'TWD',
  928: 'VES',
  929: 'MRU',
  930: 'STN',
  931: 'CUC',
  932: 'ZWL',
  933: 'BYN',
  934: 'TMT',
  936: 'GHS',
  938: 'SDG',
  940: 'UYI',
  941: 'RSD',
  943: 'MZN',
  944: 'AZN',
  946: 'RON',
  949: 'TRY',
  950: 'XAF',
  951: 'XCD',
  952: 'XOF',
  953: 'XPF',
  960: 'XDR',
  967: 'ZMW',
  968: 'SRD',
  969: 'MGA',
  970: 'COU',
  971: 'AFN',
  972: 'TJS',
  973: 'AOA',
  975: 'BGN',
  976: 'CDF',
  977: 'ВАМ',
  978: 'EUR',
  980: 'UAH',
  981: 'GEL',
  985: 'PLN',
  986: 'BRL',
  '036': 'AUD',
  '012': 'DZD',
  '032': 'ARS',
  '051': 'AMD',
  '044': 'BSD',
  '052': 'BBD',
  '048': 'BHD',
  '084': 'BZD',
  '060': 'BMD',
  '068': 'BOB',
  '096': 'BND',
  '090': 'SBD',
  '008': 'ALL',
  '064': 'BTN',
  '072': 'BWP',
  '050': 'BDT'
}

export enum TooltipPosition {
  BOTTOM = 'bottom',
  TOP = 'top'
}

export enum Character {
  NUMBER = '№'
}

export const FlowIcons = {
  state: {
    WAITING: <IconWaiting color='gray-500' />,
    IN_PROGRESS: <IconInProgress color='warning-500' />,
    NOT_STARTED: <IconWaiting color='gray-500' />,
    null: <IconQuestionAlt color='danger-500' />
  },
  result: {
    APPROVED: <IconSuccess color='success-500' />,
    REJECTED: <IconError color='danger-500' />,
    DECLINED: <IconError color='danger-500' />,
    CLOSED: <IconSuccess color='success-500' />,
    CANCELED: <IconCancelled color='danger-500' />,
    ERROR: <IconError color='danger-500' />,
    REASSIGNED: <IconReassigned color='warning-500' />,
    TODO: <IconSuccess color='info-500' />,
    SOLVED: <IconSuccess color='success-500' />,
    SIGNED: <IconSuccess color='success-500' />,
    STOPPED: <IconStop color='danger-500' />,
    null: <IconQuestionAlt color='danger-500' />
  }
}

export const FlowStatusColor = {
  state: {
    IN_PROGRESS: 'warning',
    NOT_STARTED: 'muted',
    WAITING: 'muted',
    null: 'muted'
  },
  result: {
    APPROVED: 'success',
    CANCELED: 'danger',
    DECLINED: 'danger',
    REJECTED: 'danger',
    SOLVED: 'success',
    STOPPED: 'danger',
    null: 'muted'
  }
}

export const FlowStatusGroupClass = {
  ERROR: 'danger',
  NEED_TO_PROCESS: 'not_finished',
  SUCCESS: 'success'
}

export enum UTDTitleOperationContent {
  HAS_DISAGREEMENTS = 'Принято с разногласиями',
  NO_DISAGREEMENTS = 'Принято без разногласий'
}

export const UTDTitleOperationContentOptions = [
  UTDTitleOperationContent.NO_DISAGREEMENTS,
  UTDTitleOperationContent.HAS_DISAGREEMENTS
]

export enum RecipientActionType {
  APPROVE = 'approve',
  SIGN = 'sign'
}

export enum RecipientSignType {
  QUALIFIED = 'qualified',
  SIMPLE = 'simple'
}

export const RecipientSignTypes = [RecipientSignType.QUALIFIED, RecipientSignType.SIMPLE]

export const ActionTypeToStageType = {
  [RecipientActionType.APPROVE]: StagesType.APPROVAL,
  [RecipientActionType.SIGN]: StagesType.SIGNING
}

export const SignTypeToStageType = {
  [RecipientSignType.QUALIFIED]: StagesType.SIGNING,
  [RecipientSignType.SIMPLE]: StagesType.APPROVAL
}

export enum ErrorType {
  MAX_LENGTH = 'max-length',
  REGEXP = 'regexp',
  REQUIRED = 'required'
}

// Modes for a group participating in the route:
export enum AssignMode {
  // Everyone from the group
  ALL = 'ALL',
  // Anyone from the group
  ANY = 'ANY'
}

export const ASSIGN_MODE_OPTIONS = [AssignMode.ALL, AssignMode.ANY]

export const LOWER_LAST_PAGE = 'last'
export const UPPER_LAST_PAGE = 'LAST'

export enum UserSettingsKey {
  ASIDE_WIDTH = 'asideWidth',
  INTERNAL_DOCUMENT_DIRECTION = 'internalDocumentDirection',
  TABLE_COLUMNS = 'columns',
  TABLE_DOCUMENTS = 'documents',
  TABLE_MESSAGES = 'messages',
  TABLE_MODE = 'mode',
  TABLE_SETTINGS = 'tableSettings',
  TABLE_TASKS = 'tasks',
  IS_LAST = 'isLast',
  NAV_MODE = 'navMode',
  SELECTED_CERTIFICATES = 'selectedCertificates',
  ROOT_MODE = 'rootMode'
}

export const DetailedTableColumn = {
  COMMENT: {
    key: 'comment',
    title: Document.comment
  },
  CONSTRAINTS: {
    key: 'constraintViolations',
    title: Document.checks
  },
  DOCUMENT_ID: {
    key: 'documentId',
    title: 'ID'
  },
  DOCUMENT_TIMESTAMP: {
    key: 'documentTimestamp',
    title: Document.createDate
  },
  EXTERNAL_ID: {
    key: 'externalId',
    title: Document.idDocDatasource
  },
  FLOW_COMPLETED_TIMESTAMP: {
    key: 'flowCompletedTimestamp',
    title: Document.flowCompletedTimestamp
  },
  FLOW_STAGE_TYPE: {
    key: 'flowStageType',
    title: Document.flowStageType
  },
  FLOW_STAGE_USER_NAME: {
    key: 'flowStageUserName',
    title: Document.flowStageUserName
  },
  FLOW_START_TIMESTAMP: {
    key: 'flowStartTimestamp',
    title: Document.flowStartTimestamp
  },
  FLOW_STATE: {
    key: 'flowState',
    title: Document.edoStatus
  },
  INITIATOR: {
    key: 'initiator',
    title: Document.initiator
  },
  INITIATOR_EMAIL: {
    key: 'initiatorEmail',
    title: Document.initiatorEmail
  },
  INITIATOR_POSITION: {
    key: 'initiatorPosition',
    title: Document.initiatorPosition
  },
  IS_EXTERNAL: {
    key: 'isExternal',
    title: Document.isExternal
  },
  IS_WORKFLOW_FINISHED: {
    key: 'isWorkflowFinished',
    title: Document.isWorkflowFinished
  },
  PACKAGE_DOCUMENT_COUNT: {
    key: 'packageDocumentCount',
    title: Document.inPackage
  },
  RELATED_DOCUMENT_COUNT: {
    key: 'relatedDocumentCount',
    title: Document.inRelated
  },
  SELECT_DOCUMENT: {
    key: 'selectDocument',
    title: Document.select
  },
  SUB_ORG: {
    key: 'subOrg',
    title: Common.division
  },
  TYPE: {
    key: 'type',
    title: Document.type
  },
  WORKFLOW_STATUSES: {
    key: 'workflowStatuses',
    title: Document.workflowStatuses
  },
  PREPROCESSING_STATUS: {
    key: 'preProcessingStatus',
    title: Document.preProcessingStatus
  }
}

export const DetailedTableColumnOrderTemplate = {
  beginColumns: ['SELECT_DOCUMENT', 'CONSTRAINTS', 'DOCUMENT_ID', 'DIRECTION', 'TYPE', 'DOCUMENT_DATE', 'DOCUMENT_NUMBER'],
  endColumns: [
    'INITIATOR',
    'INITIATOR_POSITION',
    'INITIATOR_EMAIL',
    'COMMENT',
    'DOCUMENT_TIMESTAMP',
    'RELATED_DOCUMENT_COUNT',
    'PACKAGE_DOCUMENT_COUNT',
    'SUB_ORG',
    'FLOW_STATE',
    'IS_EXTERNAL',
    'EXTERNAL_ID',
    'WORKFLOW_STATUSES',
    'PREPROCESSING_STATUS'
  ],
  endColumnsWithFlow: [
    'INITIATOR',
    'INITIATOR_POSITION',
    'INITIATOR_EMAIL',
    'FLOW_STAGE_TYPE',
    'FLOW_STAGE_USER_NAME',
    'COMMENT',
    'DOCUMENT_TIMESTAMP',
    'RELATED_DOCUMENT_COUNT',
    'PACKAGE_DOCUMENT_COUNT',
    'SUB_ORG',
    'FLOW_STATE',
    'FLOW_START_TIMESTAMP',
    'FLOW_COMPLETED_TIMESTAMP',
    'IS_EXTERNAL',
    'EXTERNAL_ID',
    'IS_WORKFLOW_FINISHED',
    'WORKFLOW_STATUSES',
    'PREPROCESSING_STATUS'
  ]
}

export const lookupLocalStorage = 'i18nextLng'
export const apiUrlCookieName = 'api_url'

// TODO If the backend implements the 'certificate status' field ('absent', 'valid', 'expired')
// then we can combine the values in these enums
export enum UserCertificate {
  NEW = 'new',
  EXISTING = 'existing',
  EXPIRED = 'expired',
  REQUESTED = 'requested'
}

export const constraintTypeOptions: ILibOption[] = [
  {
    label: ConstraintsTypeLabel.unique,
    value: ConstraintType.CHECK_UNIQUE
  },
  {
    label: ConstraintsTypeLabel.notNull,
    value: ConstraintType.CHECK_NOT_NULL
  },
  {
    label: ConstraintsTypeLabel.allowedInRoute,
    value: ConstraintType.ALLOWED_IN_ROUTE
  }
]

export enum ConstraintField {
  CODE = 'code',
  DOCUMENT_TYPE_KEYS = 'documentTypeKeys',
  ERROR_MESSAGE = 'errorMessage',
  FIELD_KEYS = 'fieldKeys',
  NAME = 'name',
  TYPE = 'type',
  CONSTRAINTS = 'constraints'
}

export enum MimeType {
  CER = 'application/x-x509-ca-cert',
  PDF = 'application/pdf',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export enum ApproveSecondFactor {
  EMAIL = 'EMAIL',
  NONE = 'NONE',
  SMS = 'SMS'
}

export const APPROVE_SECOND_FACTOR_OPTIONS = [
  ApproveSecondFactor.NONE,
  ApproveSecondFactor.SMS,
  ApproveSecondFactor.EMAIL
]

export enum CertificateIssueField {
  ADDRESS = 'address',
  INN = 'inn',
  PASSPORT = 'passport',
  SNILS = 'snils'
}

export enum DocumentFieldsDataKey {
  COPY = 'copyData',
  EDIT = 'editData',
  NEW_VERSION = 'newVersionData'
}

export const DOCUMENT_ADDITIONAL_RECIPIENTS_MAX = 9

export enum PreviousVersionMode {
  DOCUMENT_COPY = 'DOCUMENT_COPY',
  NEW_VERSION = 'NEW_VERSION'
}

export const SECONDS_IN_MINUTE = 60

export const urlRegexp = /^(?:http(s)?:\/\/)+[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&%'()*+,;=]+$/g

export const copiedFieldKeyRegexp = /^[A-Za-z0-9._-]+(_[0-9]+)$/g

export const copiedFieldNumberRegexp = /(_[0-9]+)$/g

export const tasksQueryLikePattern = /^([\p{L}\p{N}\p{S}\p{P}\p{Z}]){0,250}$/gu

export const phoneFieldNumberRegexp = /[^\d]/g

export const cadesError = {
  LOAD_ERROR: 'Ошибка при загрузке плагина',
  OBJECTS_ERROR: 'Плагин загружен, но не создаются объекты',
  TIMEOUT: 'Истекло время ожидания загрузки плагина',
  UNAVAILABLE: 'Плагин недоступен'
}

export const sentryAllowUrls = [
  /https:\/\/f-imf-\w+(?:-\w+)*.dev.info-logistics.eu\/static\/js/, //Dev master + branches
  'https://docuforce.infologistics.ru/static/js', //Prod RU
  'https://docuforce.infologistics.co.za/static/js', //Prod SA
  'https://docuforce.ilsbrasil.com.br/static/js' //Prod BR
]

export const CADES_ERRORS = [
  cadesError.LOAD_ERROR,
  cadesError.OBJECTS_ERROR,
  cadesError.TIMEOUT,
  cadesError.UNAVAILABLE
]

export const sentryIgnoreErrors = [
  /Unexpected token '<'/,
  ...CADES_ERRORS
]

export const SECONDS_IN_HOUR = 3600
export const DAYS_IN_YEAR = 365

export enum presetValues {
  AUTHOR = 'author',
  INITIATOR = 'initiator',
  INITIATOR_MANAGER = 'initiatorManager',
  SUBORG_MANAGER = 'suborgManager'
}

export enum NavMode {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export const SCROLL_CHECK_BOTTOM_DELAY = 100

export enum DependencyType {
  DICT_LOOKUP = 'DICT_LOOKUP',
  MATH_OPERATION = 'MATH_OPERATION'
}

export enum MathOperationType {
  MULTIPLY = 'MULTIPLY',
  ADD = 'ADD',
  DIVIDE = 'DIVIDE',
  /** некорректное название из-за ошибки названия на бэке */
  SUBSTRACTION = 'SUBSTRACTION'
}

export enum TextAlign {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER'
}

export const TEXT_ALIGN_OPTIONS = [
  TextAlign.LEFT,
  TextAlign.CENTER,
  TextAlign.RIGHT
]

export enum UserCertStatus {
  BLOCKED = 'BLOCKED',
  CONFIRMED = 'CONFIRMED',
  WAITING_CONFIRMATION = 'WAITING_CONFIRMATION'
}

export const UserCertStatuses = [
  UserCertStatus.WAITING_CONFIRMATION,
  UserCertStatus.BLOCKED
]

export const FILE_TOKEN_MIN_LENGTH = 360

export enum SelectedCertificatePowerOfAttorneyType {
  DIGITAL = 'DIGITAL',
  OTHER = 'OTHER',
  PAPER = 'PAPER'
}

export const SelectedCertificatePowerOfAttorneyTypes: SelectedCertificatePowerOfAttorneyType[] = [
  SelectedCertificatePowerOfAttorneyType.PAPER,
  SelectedCertificatePowerOfAttorneyType.DIGITAL,
  SelectedCertificatePowerOfAttorneyType.OTHER
]

export enum Currency {
  DOLLAR = 'USD',
  RAND = 'ZAR',
  REAL = 'BRL',
  RUBLE = 'RUB'
}

export const instanceCurrency: Record<Instance, Currency> = {
  [Instance.BRASIL]: Currency.REAL,
  [Instance.GLOBAL]: Currency.DOLLAR,
  [Instance.RUSSIAN]: Currency.RUBLE,
  [Instance.SOUTH_AFRICA]: Currency.RAND
}

export const currencySymbol: Record<Currency, string> = {
  [Currency.DOLLAR]: '$',
  [Currency.RAND]: 'R',
  [Currency.REAL]: 'R$',
  [Currency.RUBLE]: '₽'
}

export const altRubleSymbol = 'р.'

export const MAX_FIELD_KEY_ID = 1000

export const DOCUMENT_TYPE_KEY_BASE = 'type'

export enum RouteOwnerRole {
  AUTHOR = 'AUTHOR',
  INITIATOR = 'INITIATOR',
  USER = 'USER',
  GROUP = 'GROUP',
  MANAGER = 'MANAGER'
}

export enum RouteOwner {
  ADMINISTRATOR = 'administrator',
  MANAGER = 'manager'
}

export enum StageAssigneeActionType {
  CLOSE = 'close',
  ASSIGN_TO_USER = 'assignToUser',
  ASSIGN_TO_GROUP = 'assignToGroup',
  ASSIGN_TO_MANAGER = 'assignToManager',
  ASSIGN_TO_OWNER= 'assignToOwner'
}

export enum StageAssigneeCalculatedUser {
  AUTHOR = 'AUTHOR',
  INITIATOR = 'INITIATOR',
  INITIATOR_MANAGER = 'INITIATOR_MANAGER',
  SUBORG_MANAGER = 'SUBORG_MANAGER'
}

export enum RouteCalculatedUser {
  AUTHOR = 'AUTHOR',
  INITIATOR = 'INITIATOR',
}

export const DEADLINE_INPUT_MAX = 1000
export const DEADLINE_INPUT_MAX_LENGTH = 5

export enum NoticeEventType {
  NEW_TASK = 'NEW_TASK',
  NEW_TODO = 'NEW_TODO',
  FLOW_CHANGE = 'FLOW_CHANGE',
  FLOW_FINISHED = 'FLOW_FINISHED',
  INCOMING_MESSAGE = 'INCOMING_MESSAGE',
  INCOMING_PATCH = 'INCOMING_PATCH'
}

export const noticesPerPageDefault = 25

export enum TasksTabs {
  COMPLETED = 'completed',
  OPEN = 'open',
  NEW = 'new',
  DEADLINES_VIOLATED = 'deadlinesViolated'
}

export enum TasksQueryParams {
  COMPLETED = 'isCompleted',
  READ = 'isRead.eq',
  DUE_DATETIME_VIOLATION = 'isDueDateTimeViolation'
}

export enum SSEMessageType {
  NOTIFICATION = 'EventNotification',
  SYSTEM = 'EventSystem'
}

export const COPYRIGHT = 'ILS LLC. All rights reserved.'

export enum SigninFormMode {
  EMAIL = 'email',
  PHONE = 'phone',
  SSO = 'sso'
}

export const DOCUMENT_CARD_REGEXP = /^\/[\w-]+\/documents\/\d+$/g

export const PREPROCESSING_PROPERTIES_DEF : IPreProcessingProperties = {
  ocrType: IPreProcessingOcrType.ADVANCED,
  ocrLang: "",
  isSearchablePdf: false,
  ocrOnlyPages: null,
  aiProcessingProperties: {
    executionMode: IPreProcessingAiExecutionMode.SYNC,
    fields: null,
    isAddDictionary: false,
    messageContent: null,
    modelType: IPreProcessingAiModelType.ADVANCED,
    processOnlyFirstTokensQuantity: null,
    pythonScript: null,
    tools: null,
    onlyPages: null,
  }
}

export const PREPROCESSING_OCR_VOLUME : IPreProcessingOcrVolume[] = [
  IPreProcessingOcrVolume.ALL,
  IPreProcessingOcrVolume.ONLY_FIRST_PAGE
]

export const PREPROCESSING_OCR_TYPE : IPreProcessingOcrType[] = [
  IPreProcessingOcrType.STANDARD,
  IPreProcessingOcrType.ADVANCED
]

export const PREPROCESSING_OCR_LANG : string[] = [
  'EN',
  'RU',
  'PT'
]

export const PREPROCESSING_AI_MODEL_TYPE : IPreProcessingAiModelType[] = [
  IPreProcessingAiModelType.STANDARD,
  IPreProcessingAiModelType.ADVANCED
]

export const PREPROCESSING_AI_EXECUTION_MODE : IPreProcessingAiExecutionMode[] = [
  IPreProcessingAiExecutionMode.SYNC,
  IPreProcessingAiExecutionMode.BATCH
]

/** Типы файлов для превью в виде PDF */
export const PDF_PREVIEW_EXTENSIONS : string[] = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
]

export const ICONS_NAMES : IconList[] = [
  'IconActions' ,
  'IconAddDoc',
  'IconAdministrator',
  'IconAlarmClock',
  'IconAngle',
  'IconAngleLeft',
  'IconAngleRight',
  'IconAngleTop',
  'IconApprove',
  'IconApproveDecline',
  'IconArchive',
  'IconArchiveBoxIn',
  'IconArchiveCatalog',
  'IconArrowDown',
  'IconArrowFrom',
  'IconArrowLeft',
  'IconArrows',
  'IconArrowUp',
  'IconAttach',
  'IconBarcode',
  'IconBatch',
  'IconBell',
  'IconBriefcase',
  'IconBriefcaseLight',
  'IconBuilding',
  'IconCalendar',
  'IconCancelled',
  'IconCard',
  'IconCaret',
  'IconCaretDown',
  'IconCart',
  'IconCert',
  'IconChain',
  'IconChainHorizontal',
  'IconChainHorizontalBreak',
  'IconCheck',
  'IconCheckbox',
  'IconCheckboxAlt',
  'IconCheckboxChecked',
  'IconChecklist',
  'IconChecklistLight',
  'IconChevronsBottom',
  'IconChevronsLeft',
  'IconChevronsRight',
  'IconChevronsTop',
  'IconCircleArrow',
  'IconCircleCheck',
  'IconClose',
  'IconCloud',
  'IconCog',
  'IconControls',
  'IconCopy',
  'IconDefaultWidth',
  'IconDepartment',
  'IconDisk',
  'IconDocumentHistory',
  'IconDownload',
  'IconDropbox',
  'IconEdit',
  'IconEditAlt',
  'IconEnvelop',
  'IconEnvelopSolid',
  'IconError',
  'IconExchange',
  'IconExchangeAlt',
  'IconExpand',
  'IconFile',
  'IconFileCode',
  'IconFilePlus',
  'IconFiles',
  'IconFileSolid',
  'IconFileText',
  'IconFilterDelete',
  'IconFire',
  'IconFlagBr',
  'IconFlagEn',
  'IconFlagRu',
  'IconFlagZa',
  'IconFolderCreate',
  'IconFolderOpen',
  'IconFolderSolid',
  'IconForeign',
  'IconGraphic',
  'IconHistory',
  'IconHand',
  'IconHome',
  'IconHourglass',
  'IconIncoming',
  'IconIncomingAlt',
  'IconIncomingLight',
  'IconInfo',
  'IconInformed',
  'IconInitials',
  'IconInitialsAlt',
  'IconInProgress',
  'IconInput',
  'IconInsertBefore',
  'IconInvisible',
  'IconListAlt',
  'IconLock',
  'IconMessage',
  'IconMessageFill',
  'IconMinus',
  'IconNext',
  'IconOrders',
  'IconOutgoing',
  'IconOutgoingAlt',
  'IconOutgoingLight',
  'IconPhone',
  'IconPhoto',
  'IconPickUp',
  'IconPlay',
  'IconPlus',
  'IconPreview',
  'IconPrint',
  'IconProjectsOrganization',
  'IconProhibited',
  'IconPublish',
  'IconQuestion',
  'IconQuestionAlt',
  'IconQuestionFull',
  'IconQuestionLight',
  'IconReassigned',
  'IconReceipt',
  'IconRecycle',
  'IconRefresh',
  'IconReorder',
  'IconReplaceFile',
  'IconResend',
  'IconRowDelete',
  'IconRowInsertAfter',
  'IconRowInsertBefore',
  'IconSave',
  'IconScan',
  'IconSeal',
  'IconSearch',
  'IconSelect',
  'IconSend',
  'IconServices',
  'IconShare',
  'IconSidebar',
  'IconSignature',
  'IconSignOut',
  'IconSignIn',
  'IconSitemap',
  'IconSquareMinus',
  'IconSquarePlus',
  'IconSortingHorizontal',
  'IconSortingVertical',
  'IconSplitter',
  'IconStarAll',
  'IconStar',
  'IconStarFull',
  'IconStateApproving',
  'IconStateCreating',
  'IconStateDeclining',
  'IconStateQuestion',
  'IconStateReceiving',
  'IconStateSending',
  'IconStateSigning',
  'IconStateStop',
  'IconStateUnknown',
  'IconStop',
  'IconStopLight',
  'IconSuccess',
  'IconTable',
  'IconTasks',
  'IconText',
  'IconTimes',
  'IconTimesCircle',
  'IconTrash',
  'IconTriangle',
  'IconUndo',
  'IconUpload',
  'IconUser',
  'IconUserLight',
  'IconUserPlus',
  'IconUserScreen',
  'IconUsers',
  'IconVisible',
  'IconWait',
  'IconWaiting',
  'IconWarning',
  'IconWarningCircle',
  'IconWidth',
  'IconZoomMinus',
  'IconZoomPlus',
];
