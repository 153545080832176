import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { cloneDeep, omit } from 'lodash'
import { Action, ActionCreator } from 'redux'
import { v4 as uuidv4 } from 'uuid'
import { TFunction } from 'i18next'

import {
  formatTableFieldValueFromString,
  getConvertedFileFieldValue,
  getDocumentStages,
  getPagination,
  getTemplateFileName,
  prepareFileFieldsForServer
} from '@utils/utils'

import { ErrorCode, FieldType, PreviousVersionMode, ResponseCode } from '@const/consts'

import attachmentsService from '@services/attachments'
import documentsService from '@services/documents'

import { setPagination } from '@store/modules/pagination/actions'

import { AsyncThunk, IFileFieldValue, IParams, Nullable } from '@store/types/commonTypes'
import {
  DocumentsActions,
  DocumentsActionTypes,
  DocumentsThunkAction,
  DocumentsThunkDispatch,
  GetPreviewFileMode,
  IActionClearCurrentDocumentFiles,
  IActionClearCurrentDraftDocuments,
  IActionClearCurrentDraftMessages,
  IActionRemoveCurrentDraftDocument,
  IActionRemoveCurrentDraftMessage,
  IActionResetCurrentDocument,
  IActionResetDocuments,
  IActionResetSelectedDocuments,
  IActionSelectDocument,
  IActionSelectDocuments,
  IActionSetCurrentDocument,
  IActionSetCurrentDocumentFileFields,
  IActionSetCurrentDocumentFiles,
  IActionSetCurrentDocumentSignInfo,
  IActionSetCurrentDraftDocument,
  IActionSetCurrentDraftMessage,
  IActionSetDocumentRelation,
  IActionSetDocuments,
  IActionSetMessages,
  IActionSetReloadCurrentDocumentFileAction,
  IActionUpdateCurrentDraftDocument,
  IActionUpdateCurrentDraftMessage,
  IAttachment,
  IAttachmentBody,
  IConstraintViolation,
  ICopyDocumentData,
  IDocument,
  IDocumentDetailsField,
  IDocumentFiles,
  IDocumentSignInfo,
  IDraftDocumentConstraintViolations,
  IDraftDocumentCurrent,
  IDraftDocumentData,
  IDraftDocuments,
  IDraftMessageConstraintViolations,
  IDraftMessageCurrent,
  IDraftMessageData,
  IDraftMessages,
  IFileData,
  IFlowStages,
  IFlowState,
  IGetPreviewFile,
  IMessage,
  INewVersionDocumentData,
  IRelations,
  IResetFlowStateAction,
  ISelectedDocuments,
  ISendDocument,
  ISendDocumentData,
  ISendMessages,
  ISetAttachmentsAction,
  ISetCurrentDraftDocumentMessageParams,
  ISetFlowStateAction,
  ISetTodosAction,
  ITodo,
  IUpdatedFlowStage,
  IUpdateDocumentFieldsRequestData,
  IUpdateDraftDocumentMessageOptions
} from './types'
import { FileFieldAction } from '@common/Field/types'

// actions
const clearCurrentDocumentFilesAction: ActionCreator<Action> = (): IActionClearCurrentDocumentFiles => ({
  type: DocumentsActionTypes.CLEAR_CURRENT_DOCUMENT_FILES
})

const clearCurrentDraftDocumentsAction: ActionCreator<Action> = (): IActionClearCurrentDraftDocuments => ({
  type: DocumentsActionTypes.CLEAR_CURRENT_DRAFT_DOCUMENTS
})

const clearCurrentDraftMessagesAction: ActionCreator<Action> = (): IActionClearCurrentDraftMessages => ({
  type: DocumentsActionTypes.CLEAR_CURRENT_DRAFT_MESSAGES
})

const removeCurrentDraftDocumentAction: ActionCreator<Action> = (
  documents: IDraftDocuments
): IActionRemoveCurrentDraftDocument => ({
  payload: documents,
  type: DocumentsActionTypes.REMOVE_CURRENT_DRAFT_DOCUMENT
})

const removeCurrentDraftMessageAction: ActionCreator<Action> = (
  messages: IDraftMessages
): IActionRemoveCurrentDraftMessage => ({
  payload: messages,
  type: DocumentsActionTypes.REMOVE_CURRENT_DRAFT_MESSAGE
})

export const resetDocuments: IActionResetDocuments = {
  type: DocumentsActionTypes.RESET_DOCUMENTS
}

const setDocumentsAction: ActionCreator<Action> = (documents: IDocument[]): IActionSetDocuments => ({
  payload: documents,
  type: DocumentsActionTypes.SET_DOCUMENTS
})

const setMessagesAction: ActionCreator<Action> = (messages: IMessage[]): IActionSetMessages => ({
  payload: messages,
  type: DocumentsActionTypes.SET_DOCUMENTS_MESSAGES
})

const selectDocumentAction: ActionCreator<Action> = (oguid: string): IActionSelectDocument => ({
  payload: oguid,
  type: DocumentsActionTypes.SELECT_DOCUMENT
})

const selectDocumentsAction: ActionCreator<Action> = (documents: ISelectedDocuments): IActionSelectDocuments => ({
  payload: documents,
  type: DocumentsActionTypes.SELECT_DOCUMENTS
})

export const resetSelectedDocument: IActionResetSelectedDocuments = {
  type: DocumentsActionTypes.RESET_SELECTED_DOCUMENT
}

const setCurrentDocumentAction: ActionCreator<Action> = (document: IDocument): IActionSetCurrentDocument => ({
  payload: document,
  type: DocumentsActionTypes.SET_CURRENT_DOCUMENT
})

export const resetCurrentDocument: IActionResetCurrentDocument = {
  type: DocumentsActionTypes.RESET_CURRENT_DOCUMENT
}

const setCurrentDocumentFileFieldsAction: ActionCreator<Action> = (
    fileFields: Record<string, IFileFieldValue | IFileFieldValue[]>
): IActionSetCurrentDocumentFileFields => ({
  payload: fileFields,
  type: DocumentsActionTypes.SET_CURRENT_DOCUMENT_FILE_FIELDS
})

const setCurrentDocumentFilesAction: ActionCreator<Action> = (
  files: IDocumentFiles
): IActionSetCurrentDocumentFiles => ({
  payload: files,
  type: DocumentsActionTypes.SET_CURRENT_DOCUMENT_FILES
})

const setCurrentDocumentSignInfoAction: ActionCreator<Action> = (
  signInfo: IDocumentSignInfo
): IActionSetCurrentDocumentSignInfo => ({
  payload: signInfo,
  type: DocumentsActionTypes.SET_CURRENT_DOCUMENT_SIGN_INFO
})

const setCurrentDraftDocumentAction: ActionCreator<Action> = (
  documents: IDraftDocuments
): IActionSetCurrentDraftDocument => ({
  payload: documents,
  type: DocumentsActionTypes.SET_CURRENT_DRAFT_DOCUMENT
})

const setCurrentDraftMessageAction: ActionCreator<Action> = (
  messages: IDraftMessages
): IActionSetCurrentDraftMessage => ({
  payload: messages,
  type: DocumentsActionTypes.SET_CURRENT_DRAFT_MESSAGE
})

const updateCurrentDraftDocumentAction: ActionCreator<Action> = (
  documents: IDraftDocuments
): IActionUpdateCurrentDraftDocument => ({
  payload: documents,
  type: DocumentsActionTypes.UPDATE_CURRENT_DRAFT_DOCUMENT
})

const updateCurrentDraftMessageAction: ActionCreator<Action> = (
  messages: IDraftMessages
): IActionUpdateCurrentDraftMessage => ({
  payload: messages,
  type: DocumentsActionTypes.UPDATE_CURRENT_DRAFT_MESSAGE
})

const setDocumentRelationsAction: ActionCreator<Action> = (relations: IRelations): IActionSetDocumentRelation => ({
  payload: relations,
  type: DocumentsActionTypes.SET_DOCUMENT_RELATIONS
})

const setFlowStateAction: ActionCreator<Action> = (flowState: IFlowState): ISetFlowStateAction => ({
  payload: flowState,
  type: DocumentsActionTypes.SET_FLOW_STATE
})

export const resetFlowStateAction: IResetFlowStateAction = {
  type: DocumentsActionTypes.RESET_FLOW_STATE
}

const setTodosAction: ActionCreator<Action> = (todos: ITodo[]): ISetTodosAction => ({
  payload: todos,
  type: DocumentsActionTypes.SET_TODOS
})

const setAttachmentsAction: ActionCreator<Action> = (attachments: IAttachment[]): ISetAttachmentsAction => ({
  payload: attachments,
  type: DocumentsActionTypes.SET_ATTACHMENTS
})

const setReloadCurrentDocumentFileAction: ActionCreator<Action> = (isReloadFile: boolean): IActionSetReloadCurrentDocumentFileAction => ({
  payload: isReloadFile,
  type: DocumentsActionTypes.SET_RELOAD_CURRENT_DOCUMENT_FILE
})

export const actions: DocumentsActions = {
  clearCurrentDocumentFiles: clearCurrentDocumentFilesAction,
  clearCurrentDraftDocuments: clearCurrentDraftDocumentsAction,
  removeCurrentDraftDocument: removeCurrentDraftDocumentAction,
  clearCurrentDraftMessages: clearCurrentDraftMessagesAction,
  removeCurrentDraftMessage: removeCurrentDraftMessageAction,
  setDocuments: setDocumentsAction,
  setMessages: setMessagesAction,
  selectDocument: selectDocumentAction,
  selectDocuments: selectDocumentsAction,
  setCurrentDocument: setCurrentDocumentAction,
  setCurrentDocumentFileFields: setCurrentDocumentFileFieldsAction,
  setCurrentDocumentFiles: setCurrentDocumentFilesAction,
  setCurrentDocumentSignInfo: setCurrentDocumentSignInfoAction,
  setCurrentDraftDocument: setCurrentDraftDocumentAction,
  setCurrentDraftMessage: setCurrentDraftMessageAction,
  updateCurrentDraftDocument: updateCurrentDraftDocumentAction,
  updateCurrentDraftMessage: updateCurrentDraftMessageAction,
  setDocumentRelations: setDocumentRelationsAction,
  setFlowState: setFlowStateAction,
  setTodos: setTodosAction,
  setAttachments: setAttachmentsAction,
  setReloadCurrentDocumentFile: setReloadCurrentDocumentFileAction
}

// thunks

export const getDocumentData: ActionCreator<DocumentsThunkAction> = (documentOguid: string, fresh?: boolean) =>
  (dispatch: DocumentsThunkDispatch, getState): Promise<AxiosResponse> =>
    documentsService.getDocumentData(documentOguid)
      .then((resp) => {
        if (resp.status === ResponseCode.GET) {
          const { fields: documentFields, type: documentType } = resp.data

          const { userDocTypes } = getState().metadata

          const fileFields: Record<string, IFileFieldValue | IFileFieldValue[]> = Object.keys(documentFields)
              .reduce((acc, fieldKey) => {
                const field = userDocTypes[documentType]?.fields[fieldKey]
                if (!field) return acc

                const { isArray, type } = field
                if (type !== FieldType.FILE) return acc

                const value = getConvertedFileFieldValue(isArray, documentFields[fieldKey])

                const fieldValue = Array.isArray(value)
                  ? value.filter(Boolean)
                  : value

                return {
                  ...acc,
                  [fieldKey]: fieldValue
                }
              }, {})

          fresh && dispatch(actions.clearCurrentDocumentFiles)
          Object.keys(fileFields).length && dispatch(actions.setCurrentDocumentFileFields(fileFields))
          dispatch(actions.setCurrentDocument(resp.data))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const getDraftDocumentMessagePreview: ActionCreator<DocumentsThunkAction> = (
  documentId: string,
  requestId?: string,
  isMessage?: boolean,
  config?: AxiosRequestConfig
) => {
  return (dispatch: any, getState): Promise<any> => {
    const { draftDocuments, draftMessages } = getState().documents
    const {
      data: {
        signedContent: { fileOguid }
      },
      previewFiles
    } = isMessage ? draftMessages.current[documentId] : draftDocuments.current[documentId]

    const { data, total } = previewFiles

    return dispatch(getPreviewFile(fileOguid, String(data.length + 1), GetPreviewFileMode.SHELF, requestId, config)).then(
      ({ fileCode, mimeType, orientation, totalPages }: IGetPreviewFile) => {
        const newPreviewFiles: IDocumentFiles = fileCode
          ? {
            ...previewFiles,
            data: [
              ...data,
              {
                fileCode,
                mimeType,
                orientation
              }
            ],
            total: total === 0 ? totalPages : total
          }
          : {
            ...previewFiles,
            isAvailable: false
          }

        if (isMessage) {
          dispatch(
            updateCurrentDraftMessage(draftMessages.current[documentId].data, documentId, {
              previewFiles: newPreviewFiles
            })
          )
        } else {
          dispatch(
            updateCurrentDraftDocument(draftDocuments.current[documentId].data, documentId, {
              previewFiles: newPreviewFiles
            })
          )
        }
      }
    )
  }
}

export const getCurrentDocumentPreview: ActionCreator<DocumentsThunkAction> = (
  documentOguid: string,
  replaceFileOguid: Nullable<string>,
  requestId?: string
) => {
  return (dispatch: DocumentsThunkDispatch, getState): Promise<any> => {
    const { currentDocumentFiles } = getState().documents
    const { data, total } = currentDocumentFiles

    const mode = replaceFileOguid ? GetPreviewFileMode.SHELF : GetPreviewFileMode.ATTACH

    const oguid = replaceFileOguid ?? documentOguid

    return dispatch(getPreviewFile(oguid, String(data.length + 1), mode, requestId)).then(
      ({ fileCode, mimeType, orientation, totalPages }: IGetPreviewFile) => {
        const newCurrentDocumentFiles: IDocumentFiles = fileCode
          ? {
            ...currentDocumentFiles,
            data: [
              ...data,
              {
                fileCode,
                mimeType,
                orientation
              }
            ],
            total: total === 0 ? totalPages : total
          }
          : {
            ...currentDocumentFiles,
            isAvailable: false
          }

        dispatch(actions.setCurrentDocumentFiles(newCurrentDocumentFiles))
      }
    )
  }
}

export const getPreviewFile = (
  oguid: string,
  page: string,
  mode: GetPreviewFileMode = GetPreviewFileMode.ATTACH,
  requestId?: string,
  config?: AxiosRequestConfig,
) => {
  return (): Promise<any> => {
    const promise =
      mode === GetPreviewFileMode.SHELF
        ? documentsService.getShelfPreview(oguid, page, requestId, config)
        : documentsService.getAttachPreview(oguid, page, requestId, config)

    return promise
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          const mimeType = resp.headers['x-mime-type']
          const orientation = resp.headers['x-page-orientation']
          const totalPages = Number(resp.headers['x-total-pages'])

          return {
            fileCode: resp.data,
            mimeType,
            orientation,
            totalPages
          }
        }

        return resp
      })
  }
}

export const getDocumentSignInfo: ActionCreator<DocumentsThunkAction> = (signatureId: string) =>
  (dispatch: DocumentsThunkDispatch): Promise<AxiosResponse> =>
    documentsService.getDocumentSignInfo(signatureId)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(
            actions.setCurrentDocumentSignInfo({
              ...resp.data,
              signatureId
            })
          )
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const getMessages: ActionCreator<DocumentsThunkAction> = (params: any) =>
  (dispatch: DocumentsThunkDispatch): Promise<AxiosResponse> =>
    documentsService.getMessages({
      ...params,
      isAdditionalWorkflowInformationIncluded: false
    })
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(setMessagesAction(resp.data ?? []))
          const pagination = getPagination(resp.headers)
          pagination && dispatch(setPagination(pagination))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const removeCurrentDraftDocument: ActionCreator<DocumentsThunkAction> = (documentId: string) => {
  return (dispatch: DocumentsThunkDispatch, getState) => {
    const { draftDocuments } = getState().documents
    const newDraftDocuments = {
      ...draftDocuments,
      idList: draftDocuments.idList.filter((id) => id !== documentId)
    }
    delete newDraftDocuments.current[documentId]

    dispatch(actions.removeCurrentDraftDocument(newDraftDocuments))
  }
}

export const removeCurrentDraftMessage: ActionCreator<DocumentsThunkAction> = (documentId: string) => {
  return (dispatch: DocumentsThunkDispatch, getState) => {
    const { draftMessages } = getState().documents
    const newDraftMessages = {
      ...draftMessages,
      idList: draftMessages.idList.filter((id) => id !== documentId)
    }
    delete newDraftMessages.current[documentId]

    dispatch(actions.removeCurrentDraftMessage(newDraftMessages))
  }
}

export const sendDocumentMessageFile: ActionCreator<DocumentsThunkAction> = (
  data: FormData,
  isMessage?: boolean,
  subOrgOguid?: string,
  uuid?: string
) => {
  return (dispatch: any): Promise<AxiosResponse> => {
    return documentsService.sendFile(data)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.POST) {
          const { name, size } = data.get('file') as File
          dispatch(setCurrentDraftDocumentMessage({
            fileData: { id: resp.data, name, size },
            isMessage,
            uuid: uuid ?? uuidv4(),
            subOrgOguid
          }))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
  }
}

export const replaceDocumentMessageFile: ActionCreator<DocumentsThunkAction> = (
  data: FormData,
  id: string,
  isMessage?: boolean
) => {
  return (dispatch: any, getState): Promise<AxiosResponse> => {
    const { draftDocuments, draftMessages } = getState().documents

    return documentsService.sendFile(data)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.POST) {
          const { name: fileName, size: fileSize } = data.get('file') as File

          const previewFiles = {
            data: [],
            isAvailable: true,
            total: 0
          }

          if (isMessage) {
            const newDraftMessage: IDraftMessageData = {
              ...draftMessages.current[id].data,
              signedContent: {
                fileOguid: resp.data
              },
              fields: {
                ...draftMessages.current[id].data.fields
              }
            }

            dispatch(
              updateCurrentDraftMessage(newDraftMessage, id, {
                fileName,
                fileSize,
                previewFiles
              })
            )
          } else {
            const newDraftDocument: IDraftDocumentData = {
              ...draftDocuments.current[id].data,
              signedContent: {
                fileOguid: resp.data
              },
              fields: {
                ...draftDocuments.current[id].data.fields
              }
            }

            dispatch(
              updateCurrentDraftDocument(newDraftDocument, id, {
                fileName,
                fileSize,
                previewFiles
              })
            )
          }
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
  }
}

export const updateDocumentFields: ActionCreator<DocumentsThunkAction> = (
  { documentOguid, initiatorOguid = null, fields, subOrgOguid, details }: IUpdateDocumentFieldsRequestData,
  isIgnoreConstraints = false
) => (dispatch: DocumentsThunkDispatch, getState): Promise<AxiosResponse> => {
  const requestBody = {
    initiatorOguid,
    subOrgOguid,
    fields,
    details,
    isIgnoreConstraints
  }

  return documentsService.updateDocumentFields(documentOguid, requestBody)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.setCurrentDocument(resp.data))
      }

      return resp
    })
    .catch((err: AxiosError<IConstraintViolation[]>) => {
      if (err.response?.status === ErrorCode.CONFLICT) {
        const { currentDocument } = getState().documents
        const { data: constraintViolations } = err.response

        const newCurrentDocument = {
          ...currentDocument,
          constraintViolations
        }

        dispatch(actions.setCurrentDocument(newCurrentDocument))
      }

      return Promise.reject(err)
    })
}

export const sendDocumentCopy: ActionCreator<DocumentsThunkAction> = (
  data: ICopyDocumentData,
  isIgnoreConstraints = false
) => {
  return (dispatch: DocumentsThunkDispatch, getState): Promise<AxiosResponse> => {
    const { isFlowRequired, ...restData } = data

    const requestBody = {
      documents: [
        {
          ...restData,
          packageKey: 0,
          previousVersionMode: PreviousVersionMode.DOCUMENT_COPY
        }
      ],
      flow: null,
      isFlowRequired,
      isIgnoreConstraints
    }

    return documentsService.sendDocuments(requestBody).catch((err: AxiosError<IConstraintViolation[]>) => {
      if (err.response?.status === ErrorCode.CONFLICT) {
        const { currentDocument } = getState().documents
        const { data: constraintViolations } = err.response

        const newCurrentDocument = {
          ...currentDocument,
          constraintViolations: constraintViolations[0]
        }

        dispatch(actions.setCurrentDocument(newCurrentDocument))
      }

      return Promise.reject(err)
    })
  }
}

export const sendDocumentNewVersion: ActionCreator<DocumentsThunkAction> = (
  data: INewVersionDocumentData,
  flowStages: Nullable<IFlowStages>,
  isIgnoreConstraints = false
) => {
  return (dispatch: DocumentsThunkDispatch, getState): Promise<AxiosResponse> => {
    const requestBody = {
      documents: [
        {
          ...data,
          packageKey: 0,
          previousVersionMode: PreviousVersionMode.NEW_VERSION
        }
      ],
      flow: flowStages,
      isFlowRequired: true,
      isIgnoreConstraints
    }

    return documentsService.sendDocuments(requestBody).catch((err: AxiosError<IConstraintViolation[]>) => {
      if (err.response?.status === ErrorCode.CONFLICT) {
        const { currentDocument } = getState().documents
        const { data: constraintViolations } = err.response

        const newCurrentDocument = {
          ...currentDocument,
          constraintViolations: constraintViolations[0]
        }

        dispatch(actions.setCurrentDocument(newCurrentDocument))
      }

      return Promise.reject(err)
    })
  }
}

export const sendDocuments: ActionCreator<DocumentsThunkAction> = (
  { isRequireRecipientSignature, packageKey, recipients, isFlowRequired }: ISendDocument,
  isIgnoreConstraints = false,
  t?: TFunction
) => (dispatch: DocumentsThunkDispatch, getState): Promise<AxiosResponse> => {
  const { draftDocuments } = getState().documents
  const { userDocTypes } = getState().metadata
  const { current, idList } = draftDocuments
  const signSendMode = !!recipients?.length && isRequireRecipientSignature

  const documents = idList.map((id, i) => {
    const { fields, type, ...restDocument } =
      omit(current[id].data, 'templateBased') as ISendDocumentData

    const { title: typeTitle, fields: typeFields } = userDocTypes[type]

    const updatedFields = prepareFileFieldsForServer(fields)

    let formattedFilenameField = {}

    const fileName = getTemplateFileName(typeTitle, fields.documentDate, fields.documentNumber)

    if (typeFields?.filename?.isRequired && !fields.filename) {
      formattedFilenameField = { filename: fileName }
    }

    const document = {
      ...restDocument,
      fields: { ...updatedFields, ...formattedFilenameField },
      type
    }

    if (!document.signedContent?.fileOguid) {
      document.signedContent = null
    }

    let details: IDocumentDetailsField[] = []

    if (t) {
      const type = userDocTypes[current[id].data.type].table?.fields
      details = formatTableFieldValueFromString(cloneDeep(document.details), t, type)
    }

    return {
      ...document,
      details,
      packageKey: packageKey ? 1 : i
    }
  })

  const requestBody = {
    documents,
    flow: signSendMode
      ? {
        stages: getDocumentStages(recipients)
      }
      : null,
    isFlowRequired: signSendMode || isFlowRequired,
    isIgnoreConstraints
  }

  return documentsService.sendDocuments(requestBody)
    .then((resp: AxiosResponse) => {
      return resp
    })
    .catch((err: AxiosError<IDraftDocumentConstraintViolations[]>) => {
      if (err.response?.status === ErrorCode.CONFLICT) {
        const { data } = err.response

        const newCurrentDraftDocument: IDraftDocumentCurrent = data.reduce((acc, constraintViolations, id) => {
          const documentId = idList[id]
          const document = current[documentId]
          const newDocument = {
            ...document,
            constraintViolations
          }

          return {
            ...acc,
            [documentId]: newDocument
          }
        }, {})

        const newDraftDocuments = {
          current: newCurrentDraftDocument,
          idList
        }

        dispatch(actions.updateCurrentDraftDocument(newDraftDocuments))
      }

      return Promise.reject(err)
    })
}

export const sendMessages: ActionCreator<DocumentsThunkAction> = (
  { toOrg, proxyOrg, assignedToUserOguid, isFlowRequired, type }: ISendMessages,
  isIgnoreConstraints = false
) => (dispatch: DocumentsThunkDispatch, getState): Promise<AxiosResponse> => {
  const { draftMessages } = getState().documents
  const { current, idList } = draftMessages

  const documents = idList.map((id) => {
    const { fields, ...restMessage} = current[id].data

    const updatedFields = prepareFileFieldsForServer(fields)

    const message = {
      ...restMessage,
      fields: updatedFields
    }

    return {
      ...message,
      packageKey: null
    }
  })

  const requestBody = {
    isFlowRequired,
    flow: {
      stages: [
        {
          type,
          assignees: [
            {
              assignedToGroupOguid: null,
              assignedToUserOguid
            }
          ]
        }
      ]
    },
    toOrg: {
      orgOguid: toOrg
    },
    proxyOrg: proxyOrg
      ? {
        orgOguid: proxyOrg
      }
      : null,
    documents,
    isIgnoreConstraints
  }

  return documentsService.sendMessages([requestBody])
    .then((resp: AxiosResponse) => {
      return resp
    })
    .catch((err: AxiosError<IDraftMessageConstraintViolations[]>) => {
      if (err.response?.status === ErrorCode.CONFLICT) {
        const { data } = err.response

        const newCurrentDraftMessage: IDraftMessageCurrent = data[0].reduce((acc, constraintViolations, id) => {
          const messageId = idList[id]
          const message = current[messageId]
          const newMessage = {
            ...message,
            constraintViolations
          }

          return {
            ...acc,
            [messageId]: newMessage
          }
        }, {})

        const newDraftMessages = {
          current: newCurrentDraftMessage,
          idList
        }

        dispatch(actions.updateCurrentDraftMessage(newDraftMessages))
      }

      return Promise.reject(err)
    })
}

export const setCurrentDraftDocumentMessage: ActionCreator<DocumentsThunkAction> =
  (params: ISetCurrentDraftDocumentMessageParams) => {
    const {
      fileData,
      uuid,
      isMessage,
      isClean,
      templateBased,
      type = '',
      subOrgOguid
    } = params

    return (dispatch: DocumentsThunkDispatch, getState): Promise<any> => {
      const { id, name, size } = fileData

      const {
        user: {
          profile: {
            oguid: initiatorOguid
          }
        },

      } = getState()

      const document = {
        data: {
          comment: '',
          details: [],
          initiatorOguid,
          fields: {},
          signedContent: {
            fileOguid: id
          },
          subOrgOguid,
          templateBased,
          type
        },
        fileName: name,
        fileSize: size,
        previewFiles: {
          data: [],
          isAvailable: true,
          total: 0
        }
      }

      return new Promise((resolve) => {
        if (isMessage) {
          isClean && dispatch(actions.clearCurrentDraftMessages())

          const { draftMessages } = getState().documents
          const message = {
            ...document,
            data: {
              ...document.data,
              isRequireRecipientSignature: false
            }
          }

          const newDraftMessages = {
            ...draftMessages,
            current: {
              ...draftMessages.current,
              [uuid]: message
            },
            idList: [...draftMessages.idList, uuid]
          }

          dispatch(actions.setCurrentDraftMessage(newDraftMessages))
          resolve(getState().documents.draftMessages)
        } else {
          isClean && dispatch(actions.clearCurrentDraftDocuments())

          const { draftDocuments } = getState().documents
          const newDraftDocuments = {
            ...draftDocuments,
            current: {
              ...draftDocuments.current,
              [uuid]: document
            },
            idList: [...draftDocuments.idList, uuid]
          }

          dispatch(actions.setCurrentDraftDocument(newDraftDocuments))
          resolve(getState().documents.draftDocuments)
        }
      })
    }
  }

export const setTemplateBasedDraftDocument: ActionCreator<DocumentsThunkAction> = (
  type: string,
  subOrgOguid?: string
) => {
  return (dispatch: any) => {
    const fileData: IFileData = {
      id: '',
      name: '',
      size: 0
    }

    dispatch(setCurrentDraftDocumentMessage({
      fileData,
      templateBased: true,
      type,
      uuid: uuidv4(),
      subOrgOguid
    }))
  }
}

export const getDocuments: ActionCreator<DocumentsThunkAction> = (params: IParams) => (
  dispatch: DocumentsThunkDispatch
): Promise<AxiosResponse> =>
  documentsService.getDocuments({
    ...params,
    isAdditionalWorkflowInformationIncluded: false
  }).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(setDocumentsAction(resp.data ?? []))

      const pagination = getPagination(resp.headers)
      pagination && dispatch(setPagination(pagination))
    }

    return resp
  })

export const updateCurrentDraftDocument: ActionCreator<DocumentsThunkAction> = (
  document: IDraftDocumentData,
  uuid: string,
  options?: IUpdateDraftDocumentMessageOptions
) => {
  return (dispatch: DocumentsThunkDispatch, getState) => {
    const { draftDocuments } = getState().documents
    const currentDocument = draftDocuments.current[uuid]

    const {
      data: {
        signedContent: { fileOguid: currentFileOguid }
      }
    } = currentDocument

    const {
      signedContent: { fileOguid: newFileOguid }
    } = document

    const previewFiles =
      currentFileOguid === newFileOguid
        ? options?.previewFiles ?? currentDocument.previewFiles
        : {
          data: [],
          isAvailable: true,
          total: 0
        }

    const newDraftDocuments = {
      ...draftDocuments,
      current: {
        ...draftDocuments.current,
        [uuid]: {
          ...currentDocument,
          data: document,
          fileName: options?.fileName ?? currentDocument.fileName,
          fileSize: options?.fileSize ?? currentDocument.fileSize,
          previewFiles,
          blocked: options?.blocked,
        }
      }
    }

    dispatch(actions.updateCurrentDraftDocument(newDraftDocuments))
  }
}

export const updateCurrentDraftMessage: ActionCreator<DocumentsThunkAction> = (
  message: IDraftMessageData,
  uuid: string,
  options?: IUpdateDraftDocumentMessageOptions
) => {
  return (dispatch: DocumentsThunkDispatch, getState): Promise<any> => {
    const { draftMessages } = getState().documents
    const newDraftMessages = {
      ...draftMessages,
      current: {
        ...draftMessages.current,
        [uuid]: {
          ...draftMessages.current[uuid],
          data: message,
          fileName: options?.fileName ?? draftMessages.current[uuid].fileName,
          fileSize: options?.fileSize ?? draftMessages.current[uuid].fileSize,
          previewFiles: options?.previewFiles ?? draftMessages.current[uuid].previewFiles,
          blocked: options?.blocked,
        }
      }
    }

    return new Promise<void>((resolve) => {
      dispatch(actions.updateCurrentDraftMessage(newDraftMessages))
      resolve()
    })
  }
}

export const getDocumentRelations: ActionCreator<DocumentsThunkAction> = (documentOguid: string) =>
  (dispatch: DocumentsThunkDispatch): Promise<AxiosResponse> =>
    documentsService.getDocumentRelations(documentOguid)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setDocumentRelations(resp.data))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const getFlowState: ActionCreator<DocumentsThunkAction> = (taskOguid: string) =>
  (dispatch: DocumentsThunkDispatch): Promise<AxiosResponse> =>
    documentsService.getFlowState(taskOguid)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setFlowState(resp.data))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const updateFlowState: ActionCreator<DocumentsThunkAction> = (
  taskOguid: string,
  stages: IUpdatedFlowStage[]
) => (dispatch: DocumentsThunkDispatch): Promise<AxiosResponse> =>
    documentsService.updateFlowState(taskOguid, stages)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setFlowState(resp.data))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const getTodos: ActionCreator<DocumentsThunkAction> = (taskOguid: string) =>
  (dispatch: DocumentsThunkDispatch): Promise<AxiosResponse> =>
    documentsService.getTodos(taskOguid)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setTodos(resp.data ?? []))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const getAttachments: ActionCreator<DocumentsThunkAction> = (documentOguid: string) => {
  return (dispatch: any): Promise<AxiosResponse> => {
    return attachmentsService.getAttachments(documentOguid)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setAttachments(resp.data ?? []))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
  }
}

export const addAttachment: AsyncThunk = (documentOguid: string, data: IAttachmentBody) =>
  (dispatch: any): Promise<AxiosResponse> =>
    attachmentsService.postAttachment(documentOguid, data)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.POST) {
          dispatch(getAttachments(documentOguid))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const removeDocument: AsyncThunk = (documentOguid: string) => (
  dispatch: DocumentsThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  documentsService.removeDocument(documentOguid)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.DEL) {
        const { documents } = getState().documents
        delete documents[documentOguid]

        dispatch(setDocumentsAction(documents))
      }

      return resp
    })
    .catch((err: AxiosError) => Promise.reject(err))

export const prepareTemplateDocument: AsyncThunk = (id: string) => {
  return async (dispatch: any, getState): Promise<any> => {
    const { documents, metadata } = getState()

    const {
      draftDocuments: { current }
    } = documents

    const { userDocTypes } = metadata

    const { data } = current[id]
    const { comment, fields, initiatorOguid, subOrgOguid, type } = data

    const { documentDate, documentNumber } = fields

    const { title: typeTitle, fields: typeFields } = userDocTypes[type]

    const fileName = getTemplateFileName(typeTitle, documentDate, documentNumber)

    let formattedFilenameField = {}

    // if filename field is required but not filled
    if (typeFields?.filename?.isRequired && !fields.filename) {
      formattedFilenameField = { filename: fileName }
    }

    return await attachmentsService.generateTemplateFile(type, {
      comment,
      fields: prepareFileFieldsForServer({ ...fields, ...formattedFilenameField }, true),
      initiatorOguid,
      subOrgOguid
    }).then((resp) => {
      if (resp.status === ResponseCode.POST) {
        const preparedDocument: IDraftDocumentData = {
          ...data,
          signedContent: {
            fileOguid: resp.data
          }
        }

        if ('filename' in preparedDocument.fields) {
          preparedDocument.fields.filename = fileName
        }

        dispatch(updateCurrentDraftDocument(preparedDocument, id, { fileName }))
      }
    })
  }
}

export const prepareTemplateDocuments: ActionCreator<DocumentsThunkAction> = () => {
  return (dispatch: any, getState): Promise<any> => {
    const { draftDocuments } = getState().documents
    const { current, idList } = draftDocuments

    const promises = idList.map(
      (id): Promise<any> => {
        const documentData = current[id].data
        const { templateBased } = documentData

        if (templateBased) {
          return dispatch(prepareTemplateDocument(id))
        }

        return new Promise<void>((resolve) => resolve())
      }
    )

    return Promise.all(promises)
  }
}

export const checkoutFileFieldFile: ActionCreator<DocumentsThunkAction> = (fieldKey: string, index: number, action: FileFieldAction) => (
  async (dispatch, getState): Promise<AxiosResponse<IFileFieldValue>> => {
    const {
      currentDocument: {
        oguid,
        type: documentType
      },
      currentDocumentFileFields
    } = getState().documents

    const { userDocTypes } = getState().metadata

    const field = userDocTypes[documentType]?.fields[fieldKey]

    if (!field) return Promise.reject()

    const { isArray } = field

    let checkoutFileResp: AxiosResponse<IFileFieldValue>

    try {
      checkoutFileResp = isArray
        ? await attachmentsService.checkoutFileFieldFile(oguid, fieldKey, index, action)
        : await attachmentsService.checkoutSingleFileFieldFile(oguid, fieldKey, action)
    } catch (err) {
      return Promise.reject(err)
    }

    const { data: fileData } = checkoutFileResp

    const currentDocumentFileFieldFiles = currentDocumentFileFields[fieldKey]
    const newCurrentDocumentFileFieldFiles = Array.isArray(currentDocumentFileFieldFiles) && isArray
      ? currentDocumentFileFieldFiles.map((file) => {
        if (file.index !== index) return file

        return fileData
      })
      : fileData

    const newCurrentDocumentFileFields = {
      ...currentDocumentFileFields,
      [fieldKey]: newCurrentDocumentFileFieldFiles
    }

    return new Promise((resolve) => {
      dispatch(actions.setCurrentDocumentFileFields(newCurrentDocumentFileFields))
      resolve(checkoutFileResp)
    })
  }
)

export const postFileFieldFile: ActionCreator<DocumentsThunkAction> = (data: FormData, fieldKey: string) => (
  async (dispatch, getState): Promise<AxiosResponse<IFileFieldValue>> => {
    const {
      currentDocument: {
        oguid,
        type: documentType
      },
      currentDocumentFileFields
    } = getState().documents

    const { userDocTypes } = getState().metadata

    const field = userDocTypes[documentType]?.fields[fieldKey]

    if (!field) return Promise.reject()

    const { isArray } = field

    let shelfResp

    try {
      shelfResp = await attachmentsService.uploadToShelf(data)
    } catch (err) {
      return Promise.reject(err)
    }

    const { data: fileOguid } = shelfResp

    let postFileResp: AxiosResponse<IFileFieldValue>

    try {
      postFileResp = await attachmentsService.postFileFieldFile(oguid, fieldKey, fileOguid)
    } catch (err) {
      return Promise.reject(err)
    }

    const { data: fileData } = postFileResp

    const currentDocumentFileFieldFiles = currentDocumentFileFields[fieldKey]
    const newCurrentDocumentFileFieldFiles = Array.isArray(currentDocumentFileFieldFiles) && isArray
      ? [...currentDocumentFileFieldFiles, fileData]
      : fileData

    const newCurrentDocumentFileFields = {
      ...currentDocumentFileFields,
      [fieldKey]: newCurrentDocumentFileFieldFiles
    }

    return new Promise<AxiosResponse<IFileFieldValue>>((resolve) => {
      dispatch(actions.setCurrentDocumentFileFields(newCurrentDocumentFileFields))
      resolve(postFileResp)
    })
  }
)

export const removeFileFieldFile: ActionCreator<DocumentsThunkAction> = (fieldKey: string, index: number) => (
  async (dispatch, getState): Promise<AxiosResponse<IFileFieldValue>> => {
    const {
      currentDocument: {
        oguid,
        type: documentType
      },
      currentDocumentFileFields
    } = getState().documents

    const { userDocTypes } = getState().metadata

    const field = userDocTypes[documentType]?.fields[fieldKey]

    if (!field) return Promise.reject()

    const { isArray } = field

    let removeFileResp: AxiosResponse

    try {
      removeFileResp = isArray
        ? await attachmentsService.removeFileFieldFile(oguid, fieldKey, index)
        : await attachmentsService.removeSingleFileFieldFile(oguid, fieldKey)
    } catch (err) {
      return Promise.reject(err)
    }

    const currentDocumentFileFieldFiles = currentDocumentFileFields[fieldKey]
    const newCurrentDocumentFileFieldFiles = Array.isArray(currentDocumentFileFieldFiles) && isArray
      ? currentDocumentFileFieldFiles.filter((file) => (
        file.index !== index
      ))
      : null

    const newCurrentDocumentFileFields = {
      ...currentDocumentFileFields,
      [fieldKey]: newCurrentDocumentFileFieldFiles
    }

    return new Promise((resolve) => {
      dispatch(actions.setCurrentDocumentFileFields(newCurrentDocumentFileFields))
      resolve(removeFileResp)
    })
  }
)

export const replaceFileFieldFile: ActionCreator<DocumentsThunkAction> = (data: FormData, fieldKey: string, index: number) => (
  async (dispatch, getState): Promise<AxiosResponse<IFileFieldValue>> => {
    const {
      currentDocument: {
        oguid,
        type: documentType
      },
      currentDocumentFileFields
    } = getState().documents

    const { userDocTypes } = getState().metadata

    const field = userDocTypes[documentType]?.fields[fieldKey]

    if (!field) return Promise.reject()

    const { isArray } = field

    let shelfResp

    try {
      shelfResp = await attachmentsService.uploadToShelf(data)
    } catch (err) {
      return Promise.reject(err)
    }

    const { data: fileOguid } = shelfResp

    let replaceFileResp: AxiosResponse<IFileFieldValue>

    try {
      replaceFileResp = isArray
        ? await attachmentsService.replaceFileFieldFile(oguid, fieldKey, index, fileOguid)
        : await attachmentsService.replaceSingleFileFieldFile(oguid, fieldKey, fileOguid)
    } catch (err) {
      return Promise.reject(err)
    }

    const { data: fileData } = replaceFileResp

    const currentDocumentFileFieldFiles = currentDocumentFileFields[fieldKey]
    const newCurrentDocumentFileFieldFiles = Array.isArray(currentDocumentFileFieldFiles) && isArray
      ? currentDocumentFileFieldFiles.map((file) => {
        if (file.index !== index) return file

        return fileData
      })
      : fileData

    const newCurrentDocumentFileFields = {
      ...currentDocumentFileFields,
      [fieldKey]: newCurrentDocumentFileFieldFiles
    }

    return new Promise((resolve) => {
      dispatch(actions.setCurrentDocumentFileFields(newCurrentDocumentFileFields))
      resolve(replaceFileResp)
    })
  }
)
