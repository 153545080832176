import React, { FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useEventSource, useEventSourceListener } from '@react-nano/use-event-source'

import { Layout, Notifications } from '@infologistics/frontend-libraries'
import { LayoutWrapper, Menu, PrivateRoute, RedirectToStartPage } from '@containers'
import Page403 from '@views/misc/components/Page403'
import Page404 from '@views/misc/components/Page404'
import Page500 from '@views/misc/components/Page500'

import TokensService from '@services/tokensService'

import { displayErrorNotification, getIsInterfaceDisabled, getRoutesList } from '@utils/utils'

import {
  BASE_URL,
  BrowserStorage,
  FILTERS_DATA_UPDATE_INTERVAL,
  Instance,
  isDevelopment,
  Path,
  SSEMessageType,
  UserRole
} from '@const/consts'

import { authRoutes } from '@app/routes'

import { IApplicationState, Nullable } from '@store/types/commonTypes'
import {
  ILayoutRouterProps as IProps,
  ISSEMessage
} from './types'
import PageErrorOrgs from '@app/views/misc/components/PageErrorOrgs'
import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'
import { Preview } from '@app/views/organization/documents/components/DocumentEdit/components/Preview/Preview'

const LayoutRouter: FC<IProps> = (props) => {
  const {
    isError403,
    isError404,
    isError500,
    isErrorOrgs,
    isRootMode,
    onGetAllBadges,
    onGetBadges,
    onGetBadgesNotices,
    onGetDocumentTypes,
    onSetInstance,
    onToggleFetchFiltersData,
    userRole
  } = props

  const isAuthenticated = TokensService.checkAccessToken()

  const eventSourceRef = useRef<Nullable<EventSource>>(null)

  const [fetchFiltersData, setFetchFiltersData] = useState(0)

  const getPath = (): string => isRootMode ? '' : Path.ORGANIZATION_ROUTE

  const getPrivateRoutes = (): ReactElement => (
    <Switch>
      {getRoutesList(authRoutes)}

      <PrivateRoute exact path={getPath()} component={LayoutWrapper} />

      <Route component={RedirectToStartPage} />

      {/* <Route component={Page404} path={"*"} /> */}
    </Switch>
  )

  const handleGetBadges = (): void => {
    if (getIsInterfaceDisabled()) return

    if (!isRootMode) onGetAllBadges().catch(displayErrorNotification)
    onGetBadges().catch(displayErrorNotification)
    onGetBadgesNotices().catch(displayErrorNotification)
  }

  const handleToggleFetchFilterData = (): void => {
    if (getIsInterfaceDisabled()) return

    onToggleFetchFiltersData(true)
  }

  const renderPreview = (): ReactNode => {
    const { show } = useSelector(
      (state: IApplicationState) => state.filePreview
    );

    const classNames = {
      enter: 'fl-modal-enter',
      enterActive: 'fl-modal-enter_active',
      exit: 'fl-modal-exit',
      exitActive: 'fl-modal-exit_active',
    };

    const TIMEOUT = 300;

    return (
      <CSSTransition
        in={show}
        timeout={TIMEOUT}
        classNames={classNames}
        unmountOnExit={true}
      >
        {show ? <Preview /> : <></>}
      </CSSTransition>
    );
  };

  const renderRoutes = (): ReactElement => {
    if (isError403) {
      return (
        <Layout>
          <Route component={Page403} />
        </Layout>
      )
    }

    if (isError404) {
      return (
        <Layout>
          <Route component={Page404} />
        </Layout>
      )
    }

    if (isError500) {
      return (
        <Layout>
          <Route component={Page500} />
        </Layout>
      )
    }

    if (isErrorOrgs) {
      return (
        <Layout>
          <Route component={PageErrorOrgs} />
        </Layout>
      )
    }

    return getPrivateRoutes()
  }

  const eventSourceBaseURL = `${BASE_URL}services/push/events/subscribe`
  const userFromService = TokensService.getUserFromCookies()
  const accessToken = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_ACCESS, userFromService)

  const eventSourceURL = accessToken
    ? `${eventSourceBaseURL}?token=${accessToken}`
    : ''

  const [eventSource] = useEventSource(eventSourceURL)

  useEventSourceListener(eventSource, ['message'], ({data}) => {
    const { type } = JSON.parse(data) as ISSEMessage

    if (type !== SSEMessageType.NOTIFICATION) return

    handleGetBadges()
  })

  useEffect(() => {
    eventSourceRef.current = eventSource
  }, [eventSource])

  useEffect(() => {
    if (isAuthenticated) {
      const fetchFiltersData = window.setInterval(handleToggleFetchFilterData, FILTERS_DATA_UPDATE_INTERVAL)

      setFetchFiltersData(fetchFiltersData)

      if (userRole === UserRole.ADMINISTRATOR) {
        onGetDocumentTypes()
      }
    }

    if (!isDevelopment) {
      const instance = window.location.hostname.match(/global|ru|com.br|co.za/g)?.[0] ?? window.localStorage.getItem(BrowserStorage.INSTANCE) ?? Instance.GLOBAL

      onSetInstance(instance)
      window.localStorage.setItem(BrowserStorage.INSTANCE, instance)
    }

    return () => {
      fetchFiltersData && clearInterval(fetchFiltersData)

      eventSourceRef.current?.close()
    }
  }, [])

  return (
    <BrowserRouter>
      <div>
        <Notifications />
        <Route exact path={getPath()} component={Menu} />
        {renderRoutes()}
        {renderPreview()}
      </div>
    </BrowserRouter>
  )
}

export default LayoutRouter
