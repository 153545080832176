import cn from 'classnames'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'
import { PDFDocumentLoadingTask } from 'pdfjs-dist/types/src/pdf'
import React, {
  FC,
  FormEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import { useSelector } from 'react-redux'

import {
  Button,
  Nullable,
  Spinner,
  IconSidebar,
  useStateWithCallback
} from '@infologistics/frontend-libraries'

import {
  DEFAULT_SCALE, FILE_UNEXPECTED_ERROR_NAME,
  PAGE_MARGIN_BOTTOM,
  PdfScrollMode, PROTECTED_FILE_ERROR_NAME
} from './consts'
import { BrowserStorage, ErrorCode, FlowStatusGroupClass } from '@const/consts'

import {
  displayErrorNotification,
  displayBasicNotification,
  getBaseUrl,
  getFlowStatusColor,
  getStageType,
  getStatusText
} from '@utils/utils'
import TokensService from '@services/tokensService'

import { IApplicationState } from '@store/types/commonTypes'
import { IFileFunctionalProps as IProps } from './types'
import { IDocumentTask } from '@store/modules/documents/types'

import './viewer/viewer.css'
import './viewer/annotation_layer_builder.css'
import FileButtons from './FileButtons'
import FileThumbnailsControls from '@views/organization/documents/components/Document/components/File/FileThumbnailsControls'
import { useTranslation } from 'react-i18next'
import { DocumentMode } from '@store/modules/document/types'
import queryString from 'query-string'
import { CSSTransition } from 'react-transition-group'

import styles from './File.module.css'

import * as PDFJS from 'pdfjs-dist/web/pdf_viewer'
import * as PDFJSThumbnail from './viewer/ThumbnailViewer'
import * as PDFJSRenderingQueue from './viewer/RenderingQueue'
import * as PDFJSLinkService from './viewer/LinkService'
import * as PDFJSFindController from 'pdfjs-dist/lib/web/pdf_find_controller'

let pdfThumbnails: any = null
let pdfLoadingTask: Nullable<PDFDocumentLoadingTask> = null

const File: FC<IProps> = (props) => {
  const {
    draftDocumentId,
    replaceFileOguid,
    isFooterFixed,
    isPreviewMode,
    isMessage,
    hideBg,
    hideSharingBtn,
    hidePreview,
    extPageControl,
    closePreview,
    isAttachment,
    docFile,
    disableAdditionalHeight,
    classButtons,
    blocked,
  } = props;
  const {
    user: {
      profile: {
        oguid: profileOguid
      },
      activeOrganization: {
        oguid: orgOguid
      }
    },
    document: {
      mode: documentMode
    },
    documents: {
      currentDocument: {
        oguid: documentOguid,
        flowResult,
        flowStageType,
        flowState,
        isWorkflowFinished,
        workflowStatuses,
        isReloadFile
      },
      draftDocuments: {
        current: currentDraftDocuments
      },
      draftMessages: {
        current: currentDraftMessages
      },
    },
    metadata: {
      workflowStatuses: statuses
    },
    loader: {
      isLoading: isDocumentLoading
    },
    utils: {
      asideWidth
    }
  } = useSelector((state: IApplicationState) => state)

  const { t } = useTranslation()

  const LocalStorageThumbnailsState =
    localStorage.getItem(BrowserStorage.THUMBNAILS_STATE)

  const viewerWrapperRef = useRef<HTMLDivElement>(null)
  const viewerRef = useRef<HTMLDivElement>(null)
  const thumbnailsWrapperRef = useRef<HTMLDivElement>(null)

  const [scale, setScale] = useState<number>(DEFAULT_SCALE)
  const [pdfViewer, setPdfViewer] = useState<Nullable<any>>(null)
  const [isFileLoading, setIsFileLoading] = useState<boolean>(true)
  const [sizeDocumentWrapper, setSizeDocumentWrapper] = useStateWithCallback<Nullable<number>>(null)
  const [thumbnailsIsOpen, setThumbnailsIsOpen] =
    useState<boolean>(
      LocalStorageThumbnailsState
        ? JSON.parse(LocalStorageThumbnailsState)
        : false
    )

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [replaceFileId, setReplaceFileId] = useState<Nullable<string> | undefined>(null)
  const [hashPage, setHashPage] = useState<Nullable<number>>(null)
  const [searchText, setSearchText] = useState<Nullable<string>>(null)
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [pagesLoaded, setPagesLoaded] = useState<boolean>(false)
  const [isWordFound, setIsWordFound] = useState<boolean>(false)
  const [labelOffsetLeft, setLabelOffsetLeft] = useState<Nullable<number>>(null)
  const [errorLoad, setErrorLoad] = useState(false);
  const [needReinit, setNeedReinit] = useState(false);

  const isReplaceMode = documentMode === DocumentMode.COPY || documentMode === DocumentMode.NEW_VERSION

  const actualCurrentPage = extPageControl ? extPageControl.currentPage : currentPage;
  const actualSetCurrentPage = extPageControl ? extPageControl.setCurrentPage : setCurrentPage;
  const actualTotalPages = extPageControl ? extPageControl.totalPages : totalPages;
  const actualSetTotalPages = extPageControl ? extPageControl.setTotalPages : setTotalPages;

  const spinnerTransitionClasses = {
    enter: 'fl-loader-enter',
    enterActive: 'fl-loader-enter_active',
    exit: 'fl-loader-exit',
    exitActive: 'fl-loader-exit_active'
  }

  const BG_CLASS = !hideBg ? "bg-muted-300" : "";

  if (!GlobalWorkerOptions.workerSrc) {
    GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.min.js'
  }

  const eventBus = new PDFJS.EventBus()
  const renderingQueue = new PDFJSRenderingQueue.PDFRenderingQueue() as any
  const linkService = new PDFJSLinkService.PDFLinkService({
    eventBus,
    externalLinkTarget: 2,
    externalLinkRel: 'noopener noreferrer nofollow',
    ignoreDestinationZoom: false
  }) as any

  // TODO: Resolve types and try to get rid of 'as any' in the instances above
  const findController = new PDFJSFindController.PDFFindController({
    eventBus,
    linkService
  })

  const thumbnailsWrapperClasses = cn(
    thumbnailsIsOpen ? 'px-4' : styles.pdf_thumbnail_close,
    styles.pdf_thumbnail,
    'pb-4 pt-6 overflow-auto scrollbar-aside fl-content',
    (isFooterFixed && isPreviewMode) && styles.pdf_thumbnail_documentHeaderFixed
  )

  useEffect(() => {
    if (extPageControl && extPageControl.updatePage) {
      linkService.goToPage(actualCurrentPage);
      extPageControl.setUpdatePage(false);
    }
  }, [extPageControl?.updatePage])

  const handleShowSpinner = (val: boolean) => {
    setIsShowSpinner(val);
  }

  const getToken = (): Nullable<string> =>
    TokensService.getTokenFromCookies(BrowserStorage.TOKEN_FILE, profileOguid)

  const isShowSpinnerBlocked = isShowSpinner && !blocked

  const renderLoader = (): ReactNode =>
    <CSSTransition in={isShowSpinnerBlocked} timeout={400} classNames={spinnerTransitionClasses} unmountOnExit={true}>
      <div className={cn(styles.loader_wrapper, 'absolute')}>
        <div className={cn(styles.loader, 'absolute')}>
          <Spinner />
        </div>
      </div>
    </CSSTransition>

  const renderLabel = (): ReactNode => {
    if (draftDocumentId) return null

    let statusClass: string
    let caption: string

    if (workflowStatuses?.length) {
      const flowStatusGroup = statuses[workflowStatuses[0]].group
      const isFlowStatusGroup = Object.keys(FlowStatusGroupClass).includes(flowStatusGroup)
      const flowStatusGroupClass = isFlowStatusGroup ? styles[`label_${FlowStatusGroupClass[flowStatusGroup]}`] : styles.label_finished

      statusClass = isWorkflowFinished ? flowStatusGroupClass : styles.label_not_finished
      caption = statuses[workflowStatuses[0]].caption
    } else {
      const task: IDocumentTask = {
        result: flowResult,
        state: flowState
      }

      statusClass = styles[`label_${getFlowStatusColor(task)}`]
      const stageType = getStageType(flowStageType)
      caption = getStatusText(task, stageType, t)
    }

    if (!caption) return null

    return <p
      className={cn(styles.label, statusClass, 'absolute at-4 p-2 mt-3 ml-n4')}
      style={{ left: `${labelOffsetLeft}px` }}
    >
      {caption}
    </p>
  }

  const getMultiplier = (scale: number, pageHeight: number, pageWidth: number): number => {
    const isLandscapeOrientation = pageWidth > pageHeight

    switch (scale) {
      case 150:
        return isLandscapeOrientation ? 0.1 : 0
      case 125:
        return isLandscapeOrientation ? 0.3 : 0
      case 0:
      case 100:
        return isLandscapeOrientation ? 0.7 : 0.2
      case 75:
        return isLandscapeOrientation ? 1.25 : 0.4
      case 50:
        return isLandscapeOrientation ? 2.3 : 1
      case 25:
        return isLandscapeOrientation ? 5.2 : 3
      default:
        return 0
    }
  }

  const handleResize = (): void => {
    if (pdfViewer) setLabelOffsetLeft(pdfViewer._pages?.[0]?.div?.offsetLeft ?? null)
    setViewerWrapperHeight()
  }

  const setViewerWrapperHeight = (): void => {
    if (!pdfViewer) return
    const scale = pdfViewer?._currentScaleValue * 100 ?? DEFAULT_SCALE
    const pageHeight = +(pdfViewer._pages[0]?.height ?? 0) + PAGE_MARGIN_BOTTOM
    const pageWidth = +(pdfViewer._pages[actualTotalPages > 1 ? 1 : 0]?.width ?? 0)
    const viewer = viewerRef.current

    if (!viewer) return

    if (actualTotalPages > 1) {
      if (pdfViewer._scrollMode === PdfScrollMode.PAGE) {
        setThumbnailsIsOpen(true)
        setSizeDocumentWrapper(null)
      } else {
        const additionalHeight = disableAdditionalHeight ? 0 : Math.round(pageHeight * getMultiplier(scale, pageHeight, pageWidth))
        setSizeDocumentWrapper(viewer.offsetHeight + additionalHeight)
      }
    }
  }

  // forced scrolling is added because the function renderTextLayer (pdfjs-dist/build/pdf.js)
  // responsible for rendering the text layer of the page, works only during
  // 1) the initial file rendering;
  // 2) change of scale from larger to smaller;
  // 3) scroll within the container.
  const forceScroll = (): void => {
    viewerWrapperRef.current?.scrollBy({
      top: 1,
      left: 0,
      behavior: 'smooth'
    })
  }

  const spyScrollForThumbnails = (): void => {
    const pageNumber = pdfViewer?._location?.pageNumber ?? actualCurrentPage
    if (pdfThumbnails.currentPageNumber !== pageNumber) pdfThumbnails.scrollThumbnailIntoView(pageNumber)
    actualSetCurrentPage(Number(pageNumber))
  }

  const scrollToPage = (page: number): void => {
    linkService.goToPage(page)
    if (pdfThumbnails.currentPageNumber !== page) pdfThumbnails.scrollThumbnailIntoView(page)
    if (actualCurrentPage !== page) actualSetCurrentPage(page)
  }

  const handleSubmit = (evt: FormEvent<HTMLFormElement>, page: number): void => {
    evt.preventDefault()
    scrollToPage(page)
  }

  const handleBlur = (page: number): void => {
    scrollToPage(page)
  }

  const handleNextPrevButtonClick = (page: number): void => {
    scrollToPage(page)
  }

  useEffect(() => {
    const thumbnailsWrapper = thumbnailsWrapperRef.current
    const viewerWrapper = viewerWrapperRef.current as HTMLDivElement
    const viewer = viewerRef.current as HTMLDivElement

    if (!viewerWrapperRef || !viewerRef || !thumbnailsWrapper) return

    setPdfViewer(
      new PDFJS.PDFViewer({
        container: viewerWrapper,
        viewer,
        eventBus,
        renderingQueue,
        linkService,
        l10n: new PDFJS.GenericL10n('en-US'),
        // currentScaleValue: '100%',  typing error, restore it if there are problems
        downloadManager: new PDFJS.DownloadManager(),
        findController
      })
    )

    pdfThumbnails = new PDFJSThumbnail.PDFThumbnailViewer({
      container: thumbnailsWrapper,
      eventBus,
      renderingQueue,
      linkService,
      l10n: new PDFJS.GenericL10n('en-US')
    })

    eventBus.on('pagesinit', (e: any) => {
      e.source.update()
    })

    eventBus.on('pagesloaded', () => {
      setPagesLoaded(true)
    })

    eventBus.on('find', () => {
      setIsWordFound(true)
    })

    eventBus._on("pagechanging", ({ pageNumber, source }: any) => {
      if (source && source._scrollMode === PdfScrollMode.PAGE) {
        pdfThumbnails.scrollThumbnailIntoView(pageNumber)
        actualSetCurrentPage(pageNumber);
      }
    })
  }, [viewerWrapperRef, viewerRef, thumbnailsWrapperRef])

  useEffect(() => {
    let draftFileOguid : string | null = null
    if (!replaceFileOguid && !isAttachment && !docFile) {
      draftFileOguid = draftDocumentId
        ? isMessage
          ? currentDraftMessages[draftDocumentId].data.signedContent?.fileOguid
          : currentDraftDocuments[draftDocumentId].data.signedContent?.fileOguid
        : null;
    }

    const loadDocument = (fileToken: string): void => {
      // if (!blocked) {
        setIsFileLoading(true)

        const shelfFileOguid = replaceFileId ?? draftFileOguid

        const urlOrigin = shelfFileOguid
          ? `${getBaseUrl()}files/orgs/${orgOguid}/shelf/${shelfFileOguid}/previewPDF/${fileToken}`
          : `${getBaseUrl()}files/orgs/${orgOguid}/documents/${documentOguid}/previewPDF/${fileToken}`

        const urlAttachment = isAttachment
          ? `${getBaseUrl()}files/orgs/${orgOguid}/attachments/${replaceFileId}/previewPDF/${fileToken}`
          : urlOrigin

        const url = docFile
          ? `${getBaseUrl()}files/orgs/${orgOguid}/documents/${documentOguid}/fileField/${docFile.name}/${docFile.index !== undefined ? `index/${docFile.index}/` : ""}previewPDF/${fileToken}`
          : urlAttachment

        pdfLoadingTask = getDocument({
          url,
          disableRange: false,
          disableStream: true,
          disableAutoFetch: true,
          withCredentials: true,
          verbosity: 0 // will only print actual ERRORS messages, no WARNINGS
        })

        pdfLoadingTask
          .promise
          .then(pdf => {
            renderingQueue.setViewer(pdfViewer)
            renderingQueue.setThumbnailViewer(pdfThumbnails)
            linkService.setViewer(pdfViewer)
            linkService.setDocument(pdf)
            pdfViewer?.setDocument(pdf)
            pdfThumbnails?.setDocument(pdf)

            viewerWrapperRef.current?.addEventListener('scroll', spyScrollForThumbnails);
            window.addEventListener('resize', handleResize)

            actualSetTotalPages(pdf._pdfInfo.numPages)

            setErrorLoad(false);
          })
          .catch((error) => {
            if (error.status === ErrorCode.NOT_AUTH) {
              reloadDocument()
            } else if (error.name === PROTECTED_FILE_ERROR_NAME) {
              displayBasicNotification({
                title: t('document:fileProtected.title'),
                content: t('document:fileProtected.content'),
                type: 'error'
              })
            } else if (error.name === FILE_UNEXPECTED_ERROR_NAME) {
              displayBasicNotification({
                title: '',
                content: t('document:fileFailedToGet'),
                type: 'error'
              })
            } else {
              displayErrorNotification(error)
              setErrorLoad(true);
            }

            closePreview?.()
          })
          .finally(() => {
            setIsFileLoading(false)
          })
      // }
    }

    if (pdfViewer && pdfThumbnails) {
      const initialDocumentWithFileToken = (): void => {
        const fileToken = getToken()
        fileToken && loadDocument(fileToken)
      }

      initialDocumentWithFileToken()
    }

    const reloadDocument = (): void => {
      const { refreshToken } = TokensService.getTokensToStartup()

      TokensService.refresh(refreshToken)
        .then(() => {
          const newFileToken = getToken()
          newFileToken && loadDocument(newFileToken)
        })
        .catch(err => {
          displayErrorNotification(err)
        })
    }

    if (isReloadFile) {
      reloadDocument()
    }
  }, [pdfViewer, pdfThumbnails, replaceFileId, isReloadFile, needReinit])

  useEffect(() => {
    // when the global loader is already hidden
    if (!isDocumentLoading) {
      // wait 200ms before the spinner is shown
      setTimeout(() => {
        // some files are already downloaded by this moment
        // launch the spinner only if the file is still loading
        isFileLoading && setIsShowSpinner(true)

        // hide the spinner after 200ms
        !isFileLoading && setTimeout(() => {
          setIsShowSpinner(false)
        }, 200)
      }, 200)
    }
  }, [isFileLoading, isDocumentLoading])

  useEffect(() => {
    if (pdfViewer) {
      pdfViewer.currentScaleValue = `${scale / 100}`
      forceScroll()

      setSizeDocumentWrapper(null, setViewerWrapperHeight)
    }
  }, [scale])

  useEffect(() => {
    localStorage.setItem(
      BrowserStorage.THUMBNAILS_STATE,
      String(thumbnailsIsOpen)
    )

    if (thumbnailsIsOpen && pdfViewer && pdfThumbnails) {
      const currentPageNumber = pdfViewer._currentPageNumber
      if (currentPageNumber === 1) {
        pdfThumbnails.forceRendering()
      }
      pdfThumbnails.scrollThumbnailIntoView(currentPageNumber)
    }

    // timeout is needed because the offsetLeft value of the page in pdfViewer is not recalculated immediately
    setTimeout(() => {
      setLabelOffsetLeft(pdfViewer?._pages?.[0]?.div?.offsetLeft ?? null)
    }, 350)
  }, [thumbnailsIsOpen])

  useEffect(() => {
    if (documentMode === DocumentMode.VIEW) setReplaceFileId(null)
  }, [documentMode])

  useEffect(() => {
    setReplaceFileId(replaceFileOguid)
  }, [replaceFileOguid])

  useEffect(() => {
    if (window.location.hash) {
      const parsedHash = queryString.parse(window.location.hash)
      const page = Object.keys(parsedHash).toString()
      const search = parsedHash[page]?.toString().replace(/,/g, ' ')

      if (page && !hashPage) {
        setHashPage(Number(page))
        actualSetCurrentPage(Number(page))
      }

      if (search && !searchText) {
        setSearchText(search)
      }
    }
  }, [])

  useEffect(() => {
    if (hashPage && pdfViewer && !isFileLoading) {
      scrollToPage(hashPage)
      setHashPage(null)
    }
  }, [hashPage, pdfViewer, isFileLoading])

  useEffect(() => {
    if (searchText && pdfViewer && pagesLoaded) {
      pdfViewer.eventBus.dispatch('find', {
        type: '',
        query: searchText,
        phraseSearch: false,
        caseSensitive: false,
        entireWord: false,
        highlightAll: true,
        findPrevious: false,
        matchDiacritics: true
      });
    }
  }, [searchText, pdfViewer, pagesLoaded])

  useEffect(() => {
    if (isWordFound) {
      // timeout is needed for two reasons:
      // 1) in function scrollMatchIntoView (pdf_find_controller.js) when calling scrollIntoView()
      // the parameter scrollMatches=true is passed, so it will always scroll only to the first occurrence,
      // if we follow the link to the second and further - we need to manually scroll to the correct page after searching
      // 2) sometimes scrollMatchIntoView is launched only after scrolling (especially on small screens, the reason is not yet known)
      setTimeout(() => {
        scrollToPage(actualCurrentPage)
      }, 350)
    }
  }, [isWordFound])

  useEffect(() => {
    return () => {
      pdfLoadingTask?.destroy().catch(() => null)
    }
  }, [])

  useEffect(() => {
    if (pagesLoaded) {
      setLabelOffsetLeft(pdfViewer?._pages?.[0]?.div?.offsetLeft ?? null)
    }
  }, [pagesLoaded, scale, asideWidth, flowState])

  useEffect(() => {
    if (pdfViewer && pagesLoaded) setViewerWrapperHeight()
  }, [pdfViewer, pagesLoaded])

  return (
    <>
      { renderLoader() }
      <div
        className={cn(styles.container, BG_CLASS, 'mb-n4 relative')}
        style={{
          overflowY: isShowSpinnerBlocked ? 'hidden' : 'visible',
          height: '100% !important'
        }}
      >
        {!isFileLoading &&
          <FileButtons
            draftDocumentId={draftDocumentId}
            orgOguid={orgOguid}
            replaceFileOguid={replaceFileOguid}
            scale={scale}
            setScale={setScale}
            getToken={getToken}
            classes={cn((isFooterFixed && isPreviewMode) && styles.buttons_documentHeaderFixed)}
            classesImportant={classButtons}
            setLoading={handleShowSpinner}
            hideSharingBtn={hideSharingBtn}
            isAttachment={isAttachment}
            docFile={docFile}
          />
        }
        <div className={cn(styles.scroll_fullHeight, 'd-flex')}>
          <div
            className={cn(
              styles.thumbnails_wrapper, 'fl-content',
              (isFooterFixed && isPreviewMode) && styles.thumbnails_wrapper_documentHeaderFixed,
              { [styles.hidden]: hidePreview }
            )}
            style={{ borderRight: !thumbnailsIsOpen ? 'none' : '1px solid var(--muted-100)' }}
          >
            <div className={cn(styles.toolbar_preview_container, 'relative', BG_CLASS)}>
              <div className={cn(styles.toolbar_preview, 'd-flex flex-row align-items-center mt-1 ml-2 absolute')}>
                <Button
                  theme='text'
                  onClick={() => setThumbnailsIsOpen(!thumbnailsIsOpen)}
                  classes={cn(styles.thumbnail_hideShow_button, thumbnailsIsOpen && styles.thumbnail_hideShow_button_active, 'mr-2')}
                >
                  <IconSidebar
                    size='sm'
                    color='light'
                  />
                </Button>

                <FileThumbnailsControls
                  isThumbnailsOpen={thumbnailsIsOpen}
                  currentPage={actualCurrentPage}
                  totalPages={actualTotalPages}
                  setCurrentPage={actualSetCurrentPage}
                  onSubmit={handleSubmit}
                  onClick={handleNextPrevButtonClick}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div id='thumbnailsWrapper' className={thumbnailsWrapperClasses} ref={thumbnailsWrapperRef}/>
          </div>
          <div className='relative full-width overflow-none'>
            <div
              id='viewerWrapper'
              className={cn(styles.viewerWrapper, BG_CLASS, 'absolute atl-0 overflow-auto fl-content scrollbar-layout pt-4 px-4')}
              ref={viewerWrapperRef}
            >
              { (pagesLoaded && !isFileLoading && !isReplaceMode) && renderLabel() }
              {errorLoad && (
                <div className='d-flex justify-content-center'>
                  <Button
                    onClick={() => {
                      setNeedReinit(true);
                    }}
                    leftIcon='IconRefresh'
                  >
                    {t('common:refresh')}
                  </Button>
                </div>
              )}
              <div
                id='viewer'
                style={{ height: sizeDocumentWrapper ? `${sizeDocumentWrapper}px` : 'auto' }}
                ref={viewerRef}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default File
